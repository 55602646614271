@import url("npm:@picocss/pico/css/pico.min.css");
@import url("./colors.css");
@import url("./table.css");
@import url("./big-calendar.css");
@import url("./calendar.css");
@import url("./popup_menu.css");

:root {
  --sidebar-width: clamp(200px, 17vw, 300px);
  --menubar-image-height: 24px;
  --menubar-height: 0px;
  --spacing: 0.5rem;
  --form-element-spacing-vertical: 0.25rem;
  --form-element-spacing-horizontal: 0.5em;
  --typography-spacing-vertical: 1rem;
  --font-size: 16px !important;
  --well-background-color: var(--table-row-stripped-background-color);
  --well-border-color: var(--table-border-color);
  --small-height: max(300px, 25vh);
  --medium-height: max(350px, 40vh);
  --large-height: max(400px, 80vh);
  --stretch-min-height: max(300px, 25vh);
}

.has_menubar {
  --menubar-height: calc(
    var(--border-width) + var(--menubar-image-height) + 2 *
      var(--typography-spacing-vertical)
  );
}

.dark_mode {
  display: none !important;
}

.invert {
  filter: invert(1);
}

[data-theme="light"] {
  --well-background-color: var(--table-row-stripped-background-color);

  .light_mode {
    display: block !important;
  }

  .dark_mode {
    display: none !important;
  }
}

@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme]) {
    --well-background-color: #18232c;
  }

  .light_mode {
    display: none !important;
  }

  .dark_mode {
    display: block !important;
  }
}

[data-theme="dark"] {
  --well-background-color: #18232c;

  .light_mode {
    display: none !important;
  }

  .dark_mode {
    display: block !important;
  }
}

.display_none {
  display: none !important;
}

@media (max-width: 768px) {
  .desktop {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .mobile {
    display: none !important;
  }
}

html,
body,
#root,
main {
  min-block-size: 100%;
  display: flex;
  flex-direction: column;
}

:is(html, body, #root, main, .in_row, .region_wrapper):has(.stretch) {
  flex-grow: 1;
}

form {
  margin-block-end: 0;
}

main#homepage {
  min-block-size: unset;
}

.region_wrapper {
  display: flex;
  flex-direction: column;
}

.region_wrapper.stretch {
  min-block-size: var(--stretch-min-height);
  block-size: 100%;
  max-block-size: 90vh;
  flex-grow: 100;
}

article.region_wrapper {
  margin: 0;
  margin-block-end: var(--spacing);
  padding: var(--spacing);
  padding-block-end: 0;
  block-size: 100%;
}

article {
  margin: 0 0 var(--typography-spacing-vertical) 0;
}

.container-well {
  margin: 0 0 var(--typography-spacing-vertical) 0;
  padding: var(--block-spacing-vertical) var(--block-spacing-horizontal);
  background-color: var(--well-background-color);
  border: 1px solid var(--table-border-color);
  border-radius: var(--border-radius);
}

.container-well input,
.container-well textarea,
.container-well select {
  background-color: var(--code-background-color);
}

.applet {
  --form-label-font-weight: 500;

  padding: calc(2 * var(--spacing));
}

label.lbl {
  font-size: 0.9em;
  max-inline-size: 50vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media (min-width: 1200px) {
  .applet {
    inline-size: 100%;
    max-inline-size: unset;
  }
}

/* what's this for? */
ul {
  margin-inline-start: 0.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: var(--typography-spacing-vertical) 1rem;
  line-height: normal;
}

:is(h1, h2, h3, h4, h5, h6):first-child {
  margin-block-start: 0;
}

:is(form, p, h1, h2, h3, h4, h5, h6):last-child {
  margin-block-end: 0;
}

h1 {
  --font-size: 1.6rem;
  --typography-spacing-vertical: 1.5rem;
}

h2 {
  --font-size: 1.4rem;
  --typography-spacing-vertical: 1.3rem;
}

h3 {
  --font-size: 1.3rem;
  --typography-spacing-vertical: 1.2rem;
}

h4 {
  --font-size: 1.2rem;
  --typography-spacing-vertical: 1.1rem;
}

h5 {
  --font-size: 1.1rem;
  --typography-spacing-vertical: 1rem;
}

h6 {
  --font-size: 1rem;
  --typography-spacing-vertical: 1rem;

  font-weight: 600;
}

label {
  margin-block-end: 0;
}

svg.disabled {
  opacity: var(--form-element-disabled-opacity);
}

.flex {
  margin-inline-end: auto;
  inline-size: 100%;
  display: flex;
  flex-direction: row;
  gap: var(--grid-spacing-horizontal);
}

.flex > .region_wrapper {
  flex: 1;
}

.flex .row_gap {
  inline-size: var(--font-size);
}

.flex .vertical_sep {
  border-inline-end: 1px solid var(--muted-border-color);
  inline-size: 0;
  align-self: stretch;
}

[role="link"],
.clickable {
  cursor: pointer;
}

svg.clickable:not(.disabled):hover {
  color: var(--primary);
}

button,
a.link {
  cursor: pointer !important;
  pointer-events: unset !important;
  display: flex;
  inline-size: 100%;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-inline-size: 4em;
  margin-inline-end: 0;
}

button {
  justify-content: center;
}

button[disabled] {
  cursor: not-allowed !important;
}

.button_like {
  cursor: pointer !important;
}

.button_like[aria-disabled] {
  cursor: not-allowed !important;
  opacity: 0.75;
}

.button_like .pill {
  min-inline-size: 3ch;
}

/* same height as a button, for use in a grid */
.in_row .button_aligned {
  block-size: calc(
    var(--font-size) * var(--line-height) + var(--form-element-spacing-vertical) *
      2 + var(--border-width) * 2
  );
  display: inline-flex;
  align-items: center;
}

@media (min-width: 768px) {
  .in_row span.button_like {
    padding: calc(
        var(--form-element-spacing-vertical) + 2 * var(--border-width)
      )
      0;
  }
}

.zeroWidthSpace {
  display: none;

  --form-label-font-weight: 500;
}

.pills {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: calc(var(--spacing) / 2);
}

.pills code,
.pill {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 6px;
  color: var(--contrast);
  display: inline-flex;
  align-items: center;
}

.pagination {
  display: flex;
  align-items: center;
  color: var(--muted-color);
}

@media (min-width: 768px) {
  .pills code,
  .pill {
    max-inline-size: 20vw;
  }
}

.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input[type="range"] {
  block-size: auto !important;
  margin-block-end: calc(2 * var(--spacing)) !important;
}

.menu li {
  min-inline-size: 7em;
}

.menu li:hover {
  background-color: var(--primary-focus);
}

.combobox details[role="list"] summary:not([role]):focus-within {
  box-shadow: 0 0 0 var(--outline-width) var(--form-element-focus-color);
}

.combobox details[role="list"] summary {
  block-size: auto !important;
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: calc(var(--spacing) / 2);
}

.combobox details[role="list"][open] summary::before {
  pointer-events: none;
}

.combobox details[role="list"] summary input[type="text"] {
  flex: 1;
  block-size: auto !important;
  box-shadow: none !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.combobox details[role="list"] summary .pills {
  flex-grow: 10;
}

.combobox details[role="list"] summary .pills code {
  gap: calc(var(--spacing) / 2);
  line-height: var(--font-size);
}

.combobox .pills code.value_selected {
  box-shadow: 0 0 0 var(--outline-width) var(--form-element-focus-color);
}

.combobox details[role="list"] ul[role="list"] {
  max-block-size: max(350px, 40vh);
  overflow-y: auto;
}

details[role="list"] summary[aria-invalid] {
  padding-inline: var(--form-element-spacing-horizontal)
    calc(var(--form-element-spacing-horizontal) + 1.5rem) !important;
  background-position: center right 0.75rem;
  background-size: 1rem auto;
  background-repeat: no-repeat;
}

details[role="list"] summary[aria-invalid="false"] {
  background-image: var(--icon-valid);

  --form-element-border-color: var(--form-element-valid-border-color);
}

details[role="list"] summary[aria-invalid="true"] {
  background-image: var(--icon-invalid);

  --form-element-border-color: var(--form-element-invalid-border-color);
}

details[role="list"] summary[aria-invalid="false"]:is(:active, :focus) {
  --form-element-border-color: var(
    --form-element-valid-active-border-color
  ) !important;
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-valid-focus-color) !important;
}

details[role="list"] summary[aria-invalid="true"]:is(:active, :focus) {
  --form-element-border-color: var(
    --form-element-invalid-active-border-color
  ) !important;
  --box-shadow: 0 0 0 var(--outline-width)
    var(--form-element-invalid-focus-color) !important;
}

[dir="rtl"]
  details[role="list"]
  summary:is([aria-invalid], [aria-invalid="true"], [aria-invalid="false"]) {
  background-position: center left 0.75rem;
}

ol {
  list-style-position: inside;
  padding-inline-start: 0 !important;
}

blockquote {
  margin-block-start: 0;
}

blockquote p:last-child {
  margin-block-end: 0;
}

.icon {
  border-radius: var(--border-radius);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  inline-size: 1.5em;
  block-size: 1.5em;
}

.with_icon {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* move this to .with_icons .gap */
.label_gap {
  margin-inline-end: calc(var(--nav-element-spacing-horizontal) / 2);
}

svg,
.icon {
  flex-shrink: 0;
}

button.muted {
  background-color: var(--code-background-color);
  color: var(--code-color);
  border: 0;
}

input[type="checkbox"]:read-only {
  cursor: default;
}

input:not([type="checkbox"], [type="radio"]),
select {
  min-inline-size: 4em;
}

.flex select:not([multiple], [size]) {
  padding-inline-end: 0;
  background-position: right 0.25em center;
}

.modal_wrapper {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100vw;
  block-size: 100vh;
  background-color: rgb(0 0 0 / 50%);
  display: grid;
  place-items: center;
  z-index: 3000;
}

.modal_wrapper > article.modal {
  position: relative;
  border-radius: var(--border-radius);
  padding: 1em;
  margin: 1em;
  overflow: auto;
  background-color: var(--background-color);
  min-inline-size: 90vw;
  max-inline-size: 90vw;
  min-block-size: 90vh;
  max-block-size: 90vh;
  resize: both;
}

.modal_wrapper > article.modal > div {
  padding: 1em;
}

@media (min-width: 768px) {
  .modal_wrapper > article.modal {
    min-inline-size: 50vw;
    min-block-size: 25vh;
  }

  /* make modals that have wide tables (5 or more cols) wider by default */
  .modal_wrapper > article.modal:has(.ui_table_wrapper td:nth-child(5)) {
    min-inline-size: 75vw;
  }
}

.modal_wrapper article.alert {
  border-radius: var(--border-radius);
  padding: calc(1.5 * var(--typography-spacing-vertical));
  margin: auto;
  overflow: auto;
  min-inline-size: 20em;
}

.modal_wrapper article.login {
  position: relative;
  border-radius: var(--border-radius);
  padding: 1em;
  overflow: auto;
  background-color: var(--background-color);
}

.button_group {
  display: flex;
  gap: var(--spacing);
  margin-block-end: var(--spacing);
  flex-wrap: wrap;
  align-items: start;
}

/* keep buttons in the same line when in a flex  */
.flex > .button_group {
  flex-wrap: nowrap;
}

/* zws is always shown in flex containers because they don't wrap */
.flex .zeroWidthSpace {
  display: block !important;
}

.button_group button,
.button_group a.link {
  margin-block-end: 0;
}

@media (min-width: 768px) {
  .in_row {
    grid-template-columns: repeat(auto-fit, minmax(0%, 1fr));
    align-items: stretch;
  }

  .dialog button {
    inline-size: 100%;
  }

  .zeroWidthSpace {
    display: block;
  }

  button,
  a.link {
    inline-size: auto;
    display: inline-flex;
  }

  .button_group {
    flex-wrap: initial;
  }

  /* right align sole link button at the end of a row */
  .in_row
    > .button_group:last-child:not(:only-child)
    > span.button_like:only-child {
    margin-inline-start: auto;
  }
}

.sidebar_wrapper {
  display: flex;
  flex-direction: row;
}

.sidebar {
  --form-element-background-color: var(--background-color);

  display: flex;
  background-color: var(--well-background-color);
  border-inline-end: 1px solid var(--table-border-color);
  padding: calc(2 * var(--spacing));
  position: fixed;
  inset-block-start: var(--menubar-height);
  inset-inline-start: 0;
  inline-size: var(--sidebar-width);
  block-size: calc(100vh - var(--menubar-height));
  overscroll-behavior-y: contain;
  transform: translate(-100%);
  z-index: 10;
  transition-property: transform;
  transition-timing-function: ease;
  transition-duration: 0.2s;
}

.sidebar_close {
  display: none;
  position: absolute;
  inset-block-start: var(--spacing);
  inset-inline-start: calc(100% + 1px);
}

.sidebar_open {
  z-index: 10;
  display: grid;
  position: fixed;
  inset-block-start: calc(var(--spacing) + var(--menubar-height));
  inset-inline-start: -4px;
  padding-inline-start: 4px;
}

.sidebar_open,
.sidebar_close {
  --font-size: 1.5rem;

  cursor: pointer;
  place-content: center;
  border-radius: var(--border-radius);
  border: 1px solid var(--well-border-color);
  background-color: var(--well-background-color);
  border-inline-start: 0;
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.sidebar.open {
  transform: translate(0);
  box-shadow: var(--card-box-shadow);
}

.sidebar.open .sidebar_open {
  display: none;
}

.sidebar.open .sidebar_close {
  display: grid;
}

#root:has(.has_sidebar) .applet,
#root:has(.has_menubar) .applet {
  inline-size: 100%;
  padding: 0;
  max-inline-size: 100%;
}

/**
* Styling below makes looks consistent between the layout with no menu and menu
*/
.sidebar_content {
  padding: calc(2 * var(--spacing));
  inline-size: 100%;
  display: flex;
  flex-direction: column;
}

nav.menubar.topbar {
  padding: 0 var(--spacing) 0 0;
  inline-size: min-content;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
}

/* If a user menubar  is present, hide the system one */
#root:has(.user_menubar) nav.topbar {
  display: none;
}

@media (min-width: 768px) {
  .has_menubar {
    --menubar-image-height: 32px;
  }

  .sidebar {
    position: sticky;
    transform: none;
  }

  .sidebar_open,
  .sidebar_close {
    display: none;
  }

  .has_sidebar .sidebar_content {
    inline-size: calc(100% - var(--sidebar-width) + calc(2 * var(--spacing)));
  }

  main:has(.sidebar) {
    padding: 0;
  }
}

.sidebar figure.nav_widget li {
  display: flex;
  justify-content: start;
  margin-block-end: var(--nav-link-spacing-vertical);
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

.toast-container {
  inline-size: calc(100% - 2 * var(--spacing));
  position: fixed;
  inset-block-end: 1em;
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--spacing);
  pointer-events: none;
  padding: 0;
  z-index: 5000;
}

@media (min-width: 768px) {
  .toast-container {
    inline-size: 30ch;
    inset-inline-end: 1em;
  }
}

.toast-container .toast {
  box-sizing: border-box;
  padding: 1ch;
  margin: 0;
  will-change: transform;
}

.gallery {
  max-block-size: 50vh;
  overflow: auto;
  display: grid;
  gap: var(--spacing);
  grid-template-columns: repeat(
    auto-fit,
    minmax(calc(50% - var(--spacing) / 2), 1fr)
  );
  grid-auto-rows: fit-content(400px);
}

.gallery .gallery-card {
  position: relative;
  overflow: hidden;
}

.gallery-card img.gallery-cover {
  background-color: lightgray;
  object-fit: cover;
  block-size: 150px;
  inline-size: 100%;
  border-radius: 0.5em;
}

.gallery-card .gallery-card-title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.gallery-card p {
  font-size: smaller;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.gallery-card .gallery-card-selector {
  position: absolute;
  mix-blend-mode: difference;
  inset-block-start: 0.5em;
  inset-inline-end: 0.5em;
  margin: 0;
}

@media (min-width: 768px) {
  .gallery-s {
    grid-template-columns: repeat(
      auto-fit,
      minmax(calc(15% - var(--spacing)), 1fr)
    );
    grid-auto-rows: fit-content(300px);
  }

  .gallery-s img.gallery-cover {
    block-size: 150px;
  }

  .gallery-m {
    grid-template-columns: repeat(
      auto-fit,
      minmax(calc(25% - var(--spacing)), 1fr)
    );
    grid-auto-rows: fit-content(350px);
  }

  .gallery-m img.gallery-cover {
    block-size: 200px;
  }

  .gallery-l {
    grid-template-columns: repeat(
      auto-fit,
      minmax(calc(50% - var(--spacing)), 1fr)
    );
    grid-auto-rows: fit-content(400px);
  }

  .gallery-l img.gallery-cover {
    block-size: 250px;
  }
}

.centered {
  inline-size: 100%;
  block-size: 80vh;
  display: grid;
  place-items: center;
  z-index: 500;
}

.centered article {
  padding: 1em 4em 2em;
}

.radio_vertical,
.checkbox_vertical,
.radio_horizontal,
.checkbox_horizontal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (min-width: 768px) {
  .radio_horizontal,
  .checkbox_horizontal {
    flex-direction: row;
    gap: var(--form-element-spacing-horizontal);
  }

  /* valign radio/checkbox with other form elemetns in a row */
  .in_row
    > .grid-span
    > :is(.radio_group, .checkbox_group)
    :is(.radio_horizontal, .checkbox_horizontal),
  .in_row > .boolean_field .checkbox_label,
  .in_row > .grid-span > .boolean_field .checkbox_label {
    padding: 0;
    padding-block: var(--form-element-spacing-vertical)
      calc(var(--form-element-spacing-vertical) + 3 * var(--border-width));
  }
}

label.radio_label,
label.checkbox_label {
  display: inline-block;
  white-space: nowrap;
  font-size: var(--font-size);

  --form-label-font-weight: var(--font-weight);
}

nav.menubar {
  display: flex;
  align-items: center;
  padding: 0 calc(2 * var(--spacing));
  inline-size: 100%;
  z-index: 2000;
}

nav.menubar > ul {
  gap: var(--nav-element-spacing-horizontal);
}

nav.menubar > ul > li {
  padding: 0;
}

/* match button dimensions */
nav.menubar [role="link"] {
  padding: calc(var(--border-width) + var(--form-element-spacing-vertical))
    calc(var(--border-width) + var(--form-element-spacing-horizontal));
  margin: 0;
}

nav.menubar [role="link"]:hover {
  --background-color: var(--primary-focus);
}

/* right align menu bar menus */
nav.menubar details[role="list"] summary[role="link"] + ul {
  margin-inline-end: calc(var(--nav-link-spacing-horizontal) * -1);
}

nav.menubar .menu_heading {
  font-weight: bold;
}

nav.menubar .menu_parent::after {
  margin-inline-start: 0.25rem;
}

nav.menubar h5 {
  margin: 0;
}

nav.menubar.user_menubar {
  /** sticky leaves the necessary space for the menu, with fixed, the topmost content overlaps */
  position: sticky;
  inset-block-start: 0;
  block-size: var(--menubar-height);
  background-color: var(--well-background-color);
  border-block-end: var(--border-width) solid var(--table-border-color);
  z-index: 1000;
}

nav.menubar.user_menubar > img {
  max-inline-size: 33vw;
  block-size: var(--menubar-image-height);
}

nav.breadcrumb {
  margin-block-end: var(--typography-spacing-vertical);
}

nav.breadcrumb ul {
  gap: var(--nav-element-spacing-horizontal);
}

nav.breadcrumb ul li {
  padding: 0;

  --font-size: 1.1rem;

  font-size: var(--font-size);
  font-weight: 700;
}

nav.breadcrumb .breadcrumb_sep {
  color: var(--muted-color);
  font-weight: lighter;
}

/* remove once margin-bottoms are removed */
hr {
  margin: var(--typography-spacing-vertical) 0;
  inline-size: 100%;
}

*:has(+ hr),
*:has(+ hr) > *:last-child,
*:has(+ hr) > *:last-child > *:last-child,
*:has(+ hr) > *:last-child > *:last-child > *:last-child {
  margin-block-end: 0;
}

:is(.sidebar_content, main)
  > .region_wrapper:last-child
  > .ui_table_wrapper:last-child {
  margin-block-end: 0;
}

.tooltip {
  inline-size: max-content;

  --color: var(--tooltip-color);

  color: var(--tooltip-color);
  background-color: var(--tooltip-background-color);
  padding: 0.25em 0.5em;
  border-radius: 0.25em;
  font-size: smaller;
}

.tooltip_inline {
  display: inline-flex;
}

.tooltip svg {
  color: var(--tooltip-background-color);
}

.img_container {
  position: relative;
  display: inline-block;
}

.img_container .btn {
  padding: var(--border-radius);
}

.img_container .btn svg {
  inline-size: 16px;
  block-size: 16px;
  display: block;
}

.lower_right {
  position: absolute;
  inset-block-end: var(--border-radius);
  inset-inline-end: var(--border-radius);
}

@media (hover: hover) {
  .img_container .btn {
    display: none;
  }

  .img_container:hover .btn {
    display: block;
  }
}

.media_busy_wrapper {
  position: relative;
}

.media_busy_wrapper .busy {
  inline-size: 100%;
  block-size: 100%;
  display: grid;
  place-content: center;
  position: absolute;
  z-index: 1;
}

.media_container :is(img, video, iframe) {
  inline-size: 100%;
  block-size: 100%;
  border-radius: var(--border-radius);
  object-fit: cover;
}

audio {
  inline-size: 100%;
}

.media_container.s,
img.s,
video.s,
audio.s {
  max-block-size: max(300px, 25vh);
}

img.s,
video.s {
  display: block;
  border-radius: var(--border-radius);
  object-fit: cover;
}

.media_container.m,
img.m,
video.m,
audio.m {
  max-block-size: max(350px, 40vh);
}

img.m,
video.m {
  display: block;
  border-radius: var(--border-radius);
  object-fit: cover;
}

.media_container.l,
img.l,
video.l,
audio.l {
  max-inline-size: 100%;
  max-block-size: max(400px, 80vh);
  inline-size: 100%;
}

.in_row img.s,
.in_row video.s,
.in_row img.m,
.in_row video.m,
img.l,
video.l {
  display: block;
  border-radius: var(--border-radius);
  block-size: auto;
  object-fit: cover;
}

.feather {
  inline-size: var(--font-size);
  block-size: var(--font-size);
  stroke: currentcolor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.addon_container {
  display: flex;
  inline-size: 100%;
  align-content: flex-start;
  flex-wrap: nowrap;
}

.input_postfix,
.input_prefix {
  display: inline-block;
  font-size: 1rem;
  font-weight: var(--font-weight);
  line-height: var(--line-height);
  block-size: calc(
    1rem * var(--line-height) + var(--form-element-spacing-vertical) * 2 +
      var(--border-width) * 2
  );
  padding: var(--form-element-spacing-vertical)
    var(--form-element-spacing-horizontal);
  margin-block-end: var(--spacing);
  border: var(--border-width) solid var(--form-element-border-color);
  border-radius: var(--border-radius);
  color: var(--code-color);
  background: var(--code-background-color);
  white-space: nowrap;
}

.input_postfix {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
  border-inline-start: 0;
}

.input_prefix {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-inline-end: 0;
}

.svg_icon {
  line-height: calc((var(--font-size) + 1em * var(--line-height)) / 2);
}

.svg_icon svg {
  inline-size: 1rem;
  block-size: 1rem;
  display: inline-block;
}

input.has_postfix {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

input.has_prefix {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.close_icon {
  --font-size: 1.5rem;

  z-index: 10;
  display: block;
  background-position: center;
  position: absolute;
  inset-block-start: 0.5em;
  inset-inline-end: 0.5em;
  opacity: 0.75;
  cursor: pointer;
}

.txt {
  margin-block-end: var(--spacing);
  line-height: 1.5rem;
}

.tooltip.centered_tooltip {
  align-items: center;
  margin-block-start: 2px;
}

.txt_info {
  display: flex;
  align-items: center;
  gap: calc(var(--spacing) / 2);
}

.txt_info svg {
  inline-size: var(--font-size);
  block-size: var(--font-size);
}

.txt_info :is(h1, h2, h3, h4, h5, h6) {
  margin-block: 0;
}

.flex:has(input, select:not([multiple], [size]), .button_like, button)
  > div.txt,
.flex:has(input, select:not([multiple], [size]), .button_like, button)
  > div
  > div.txt {
  padding: calc(var(--form-element-spacing-vertical) + 2 * var(--border-width))
    0;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .in_row:has(input, select:not([multiple], [size]), .button_like, button)
    > div.txt,
  .in_row:has(input, select:not([multiple], [size]), .button_like, button)
    > div
    > div.txt {
    padding: calc(
        var(--form-element-spacing-vertical) + 2 * var(--border-width)
      )
      0;
    white-space: nowrap;
  }
}

.flex > div.txt:last-child,
.flex > div > div.txt:last-child {
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex > .spring {
  flex-grow: 1;
}

/* if a flex contains only two items, and the 2nd item is a button group, expand the first one */
.flex > *:first-child:nth-last-child(2):has(+ .button_group) {
  flex: 1;
}

.txt-size-xs {
  --font-size: 0.8rem;

  font-size: 0.8rem;
}

.txt-size-s {
  --font-size: 0.95rem;

  font-size: 0.95rem;
}

.txt-size-l {
  --font-size: 1.3rem;

  font-size: 1.3rem;
}

.txt-size-xl {
  --font-size: 1.6rem;

  font-size: 1.6rem;
}

.txt-align-center {
  text-align: center;
}

.txt-align-end {
  text-align: end;
}

.txt-color-success {
  padding: var(--form-element-spacing-vertical)
    var(--form-element-spacing-horizontal);
  color: var(--contrast);
  background-color: var(--color-success);
  border-radius: var(--border-radius);
  border-color: var(--color-success);
}

.txt-color-error {
  padding: var(--form-element-spacing-vertical)
    var(--form-element-spacing-horizontal);
  color: var(--contrast);
  background-color: var(--color-error);
  border-radius: var(--border-radius);
  border-color: var(--color-error);
}

.txt-color-neutral {
  padding: var(--form-element-spacing-vertical)
    var(--form-element-spacing-horizontal);
  color: var(--contrast);
  background-color: var(--muted-border-color);
  border-radius: var(--border-radius);
  border-color: var(--muted-border-color);
}

.txt-color-muted {
  color: var(--muted-color);
}

/* when showing pills,  horiz = vert padding */
details[role="list"] summary:not([role]):has(.pills) {
  padding: var(--form-element-spacing-vertical);
}

/* fix to combobox down chevron */
details[role="list"] summary::after,
li[role="list"] > a::after {
  flex-shrink: 0;
  margin-inline-start: 0;
}

/* we move the ul in detail role=list into a portal, so we need to recreate pico's css */
ul[role="menu"] {
  max-block-size: max(400px, 50vh);
  overflow-y: auto;
  display: flex;
  z-index: 99;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border: var(--border-width) solid var(--dropdown-border-color);
  border-radius: var(--border-radius);
  border-start-end-radius: 0;
  border-start-start-radius: 0;
  background-color: var(--dropdown-background-color);
  box-shadow: var(--card-box-shadow);
  color: var(--dropdown-color);
  white-space: nowrap;
}

ul[role="menu"] li {
  flex-basis: 1;
  inline-size: 100%;
  margin-block-end: 0;
  padding: calc(var(--form-element-spacing-vertical) * 0.5)
    var(--form-element-spacing-horizontal);
  list-style: none;
}

ul[role="menu"] li.selected {
  background-color: var(--primary-focus);
}

ul[role="menu"] li:first-of-type {
  margin-block-start: calc(var(--form-element-spacing-vertical) * 0.5);
}

ul[role="menu"] li:last-of-type {
  margin-block-end: calc(var(--form-element-spacing-vertical) * 0.5);
}

ul[role="menu"] li a {
  display: block;
  margin: calc(var(--form-element-spacing-vertical) * -0.5)
    calc(var(--form-element-spacing-horizontal) * -1);
  padding: calc(var(--form-element-spacing-vertical) * 0.5)
    var(--form-element-spacing-horizontal);
  overflow: hidden;
  color: var(--dropdown-color);
  text-decoration: none;
  text-overflow: ellipsis;
}

ul[role="menu"] li a:hover {
  background-color: var(--dropdown-hover-background-color);
}

ul[role="menu"] li code {
  color: var(--contrast);
}

.indent-1 {
  margin-inline-start: calc(
    1 * var(--font-size) + var(--nav-element-spacing-horizontal) / 2
  );
}

.indent-2 {
  margin-inline-start: calc(
    2 * var(--font-size) + var(--nav-element-spacing-horizontal) / 2
  );
}

.indent-3 {
  margin-inline-start: calc(
    3 * var(--font-size) + var(--nav-element-spacing-horizontal) / 2
  );
}

.indent-4 {
  margin-inline-start: calc(
    4 * var(--font-size) + var(--nav-element-spacing-horizontal) / 2
  );
}

.indent-5 {
  margin-inline-start: calc(
    5 * var(--font-size) + var(--nav-element-spacing-horizontal) / 2
  );
}

.indent-6 {
  margin-inline-start: calc(
    6 * var(--font-size) + var(--nav-element-spacing-horizontal) / 2
  );
}

.indent-7 {
  margin-inline-start: calc(
    7 * var(--font-size) + var(--nav-element-spacing-horizontal) / 2
  );
}

.indent-8 {
  margin-inline-start: calc(
    8 * var(--font-size) + var(--nav-element-spacing-horizontal) / 2
  );
}

.grid-span {
  display: flex;
  flex-direction: column;
}

.grid-align-left {
  justify-self: left;
}

.grid-align-center {
  justify-self: center;
}

.grid-align-right {
  justify-self: end;
}

.flex-align-left {
  justify-content: flex-start;
}

.flex-align-center {
  justify-content: center;
}

.flex-align-right {
  justify-content: flex-end;
}

.flex-align-left > div.txt:last-child,
.flex-align-center > div.txt:last-child,
.flex-align-right > div.txt:last-child {
  flex-grow: 0;
}

.widget-align-container {
  display: flex;
}

.widget-align-right {
  justify-content: flex-end;
}

.widget-align-left {
  justify-content: flex-start;
}

.widget-align-center {
  justify-content: center;
}

/* used Row.tsx md`{a} {b} {b} {b} {c}` */
@media (min-width: 768px) {
  .grid-span-2 {
    grid-column: span 2;
  }

  .grid-span-3 {
    grid-column: span 3;
  }

  .grid-span-4 {
    grid-column: span 4;
  }

  .grid-span-5 {
    grid-column: span 5;
  }

  .grid-span-6 {
    grid-column: span 6;
  }

  .grid-span-7 {
    grid-column: span 7;
  }

  .grid-span-8 {
    grid-column: span 8;
  }
}

li[aria-disabled] {
  opacity: var(--form-element-disabled-opacity);
}

nav ol:last-of-type,
nav ul:last-of-type {
  margin-inline-end: 0;
}

nav ol:first-of-type,
nav ul:first-of-type {
  margin-inline-start: 0;
}

:where(figure.nav_widget nav li)::before {
  float: inline-start;
  content: initial;
}

figure.nav_widget nav > ul {
  gap: calc(var(--nav-element-spacing-horizontal) * 1.5);
}

figure.nav_widget li {
  padding: 0;
  display: inline-flex;
  place-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

figure.nav_widget li .large_icon {
  inline-size: calc(3em + var(--outline-width) * 2);
  block-size: calc(3em + var(--outline-width) * 2);
}

figure.nav_widget li.selected {
  font-weight: bold;
  text-decoration: underline;
}

.sidebar figure.nav_widget {
  overflow-x: initial;
}

.sidebar figure.nav_widget li.selected {
  text-decoration: none;
}

figure.nav_widget li.selected .large_icon {
  margin: var(--outline-width);
  box-shadow: 0 0 0 var(--outline-width) var(--form-element-active-border-color);
}

figure.nav_widget.tabs nav {
  border-block-end: 1px solid var(--form-element-border-color);
}

figure.nav_widget.tabs nav ul {
  margin-inline-start: var(--spacing);
}

figure.nav_widget.tabs li {
  border: 1px solid var(--form-element-border-color);
  border-block-end: 0;
  padding: var(--form-element-spacing-vertical)
    var(--form-element-spacing-horizontal);
  border-radius: var(--border-radius);
  border-end-start-radius: 0;
  border-end-end-radius: 0;
  inset-block-start: 1px;
  position: relative;
}

figure.nav_widget.tabs li.selected {
  text-decoration: none;
  border-block-end: 1px solid var(--card-background-color);
}

.info_bar {
  border-radius: var(--border-radius);
  padding: var(--form-element-spacing-vertical)
    var(--form-element-spacing-horizontal);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block-end: var(--spacing);
}

.info_bar_msg {
  display: flex;
  align-items: center;
}

.info_bar_msg > svg {
  inline-size: 1rem;
  block-size: 1rem;
}

.info_bar .options {
  display: flex;
  align-items: center;
  gap: calc(var(--nav-element-spacing-horizontal) * 2);
}

#floating-ui-root,
div[data-floating-ui-portal] {
  z-index: 6000;
}

.profile_container {
  display: flex;
  align-items: center;
  gap: var(--spacing);
}

.avatar {
  user-select: none;
  block-size: 1.5em;
  inline-size: 1.5em;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 1px solid var(--table-border-color);
  background-position: center;
  background-size: cover;
  text-transform: uppercase;
  font-size: 1em;
  display: grid;
  place-content: center;
  position: relative;
}

.menubar .avatar {
  background-color: var(--primary-focus);
  color: var(--contrast);
}

.stacked_bar {
  inline-size: 100%;
}

.stacked_bar_inner {
  inline-size: 100%;
  display: inline-flex;
  block-size: 0.5rem;
  overflow: hidden;
  border: 0;
  border-radius: var(--border-radius);
  background-color: var(--progress-background-color);
}

.stacked_bar_inner div {
  block-size: 100%;
}

.ui_chart {
  position: relative;
}

.ui_chart.s canvas {
  max-block-size: max(300px, 25vh);
}

.ui_chart.m canvas {
  max-block-size: max(350px, 33vh);
}

.ui_chart.l canvas {
  max-block-size: max(400px, 50vh);
}

.ui_chart_pie {
  padding: var(--spacing);
}

.ui_chart_pie.s {
  block-size: max(300px, 25vh);
}

.ui_chart_pie.m {
  block-size: max(350px, 33vh);
}

.ui_chart_pie.l {
  block-size: max(400px, 50vh);
  padding: calc(2 * var(--spacing));
}

.google_maps_wrapper {
  inline-size: 100%;
  border-radius: var(--border-radius);
}

.google_maps_wrapper[aria-busy],
.google_maps_wrapper[aria-invalid] {
  display: grid;
  place-content: center;
}

.google_maps_wrapper * {
  all: revert;
}

/* fix gmaps issue */
.gm-style-moc {
  pointer-events: none;
}

.google_maps_wrapper.s {
  block-size: max(300px, 25vh);
}

.google_maps_wrapper.m {
  block-size: max(400px, 50vh);
}

.google_maps_wrapper.l {
  block-size: max(600px, 90vh);
}

.joystick {
  inline-size: 200px;
  block-size: 200px;
  position: relative;
  border-radius: var(--border-radius);
  background-color: var(--muted-border-color);
}

.joystick[aria-disabled] {
  opacity: var(--form-element-disabled-opacity);
}

.joystick .bounds {
  border-radius: 50%;
  border: 2px solid var(--form-element-border-color);
  inline-size: 150px;
  block-size: 150px;
  inset-block-start: 25px;
  inset-inline-start: 25px;
  position: absolute;
}

.joystick:focus .bounds {
  border-color: var(--form-element-active-border-color);
}

.joystick .stick {
  border-radius: 50%;
  background-color: black;
  inline-size: 50px;
  block-size: 50px;
  margin: 75px;
  inset-block-start: 0;
  inset-inline-start: 0;
  position: absolute;
}

div.combobox[aria-disabled="true"] {
  pointer-events: none;
}

/** This makes the combobox / searchable select disabled styling same as the other form elements */
div.combobox[aria-disabled="true"] summary {
  --form-element-border-color: var(--form-element-disabled-border-color);

  background-color: var(--form-element-disabled-background-color);
  opacity: var(--form-element-disabled-opacity);
}

footer {
  margin-block-start: auto;
}

@media (min-width: 768px) {
  .widgets_grid {
    grid-gap: var(--grid-spacing-vertical) var(--grid-spacing-horizontal);
    grid-template-columns: repeat(auto-fit, minmax(0%, 1fr));
    margin: 0;
    display: grid;
  }
}

.drop_zone {
  color: var(--code-color);
  line-height: calc(
    var(--spacing) + 2 *
      calc(1.5em + var(--form-element-spacing-vertical) + var(--border-width))
  );
  display: grid;
  place-content: center;
  border-width: var(--border-width);
  border-style: dashed;
  border-color: var(--form-element-border-color);
  border-radius: var(--border-radius);
  background-color: var(--dropdown-background-color);
  padding: var(--form-element-spacing-vertical)
    var(--form-element-spacing-horizontal);
  margin-block-end: var(--spacing);
  overflow: hidden;
}

.drop_zone.drag_over {
  background-color: var(--form-element-focus-color);
  cursor: copy;
}

.drop_zone .with_icon {
  display: flex;
}
