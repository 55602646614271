:root {
  --font-family: system-ui, -apple-system, "Segoe UI", "Roboto", "Ubuntu", "Cantarell", "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --line-height: 1.5;
  --font-weight: 400;
  --font-size: 16px;
  --border-radius: .25rem;
  --border-width: 1px;
  --outline-width: 3px;
  --spacing: 1rem;
  --typography-spacing-vertical: 1.5rem;
  --block-spacing-vertical: calc(var(--spacing) * 2);
  --block-spacing-horizontal: var(--spacing);
  --grid-spacing-vertical: 0;
  --grid-spacing-horizontal: var(--spacing);
  --form-element-spacing-vertical: .75rem;
  --form-element-spacing-horizontal: 1rem;
  --nav-element-spacing-vertical: 1rem;
  --nav-element-spacing-horizontal: .5rem;
  --nav-link-spacing-vertical: .5rem;
  --nav-link-spacing-horizontal: .5rem;
  --form-label-font-weight: var(--font-weight);
  --transition: .2s ease-in-out;
  --modal-overlay-backdrop-filter: blur(.25rem);
}

@media (min-width: 576px) {
  :root {
    --font-size: 17px;
  }
}

@media (min-width: 768px) {
  :root {
    --font-size: 18px;
  }
}

@media (min-width: 992px) {
  :root {
    --font-size: 19px;
  }
}

@media (min-width: 1200px) {
  :root {
    --font-size: 20px;
  }
}

@media (min-width: 576px) {
  body > footer, body > header, body > main, section {
    --block-spacing-vertical: calc(var(--spacing) * 2.5);
  }
}

@media (min-width: 768px) {
  body > footer, body > header, body > main, section {
    --block-spacing-vertical: calc(var(--spacing) * 3);
  }
}

@media (min-width: 992px) {
  body > footer, body > header, body > main, section {
    --block-spacing-vertical: calc(var(--spacing) * 3.5);
  }
}

@media (min-width: 1200px) {
  body > footer, body > header, body > main, section {
    --block-spacing-vertical: calc(var(--spacing) * 4);
  }
}

@media (min-width: 576px) {
  article {
    --block-spacing-horizontal: calc(var(--spacing) * 1.25);
  }
}

@media (min-width: 768px) {
  article {
    --block-spacing-horizontal: calc(var(--spacing) * 1.5);
  }
}

@media (min-width: 992px) {
  article {
    --block-spacing-horizontal: calc(var(--spacing) * 1.75);
  }
}

@media (min-width: 1200px) {
  article {
    --block-spacing-horizontal: calc(var(--spacing) * 2);
  }
}

dialog > article {
  --block-spacing-vertical: calc(var(--spacing) * 2);
  --block-spacing-horizontal: var(--spacing);
}

@media (min-width: 576px) {
  dialog > article {
    --block-spacing-vertical: calc(var(--spacing) * 2.5);
    --block-spacing-horizontal: calc(var(--spacing) * 1.25);
  }
}

@media (min-width: 768px) {
  dialog > article {
    --block-spacing-vertical: calc(var(--spacing) * 3);
    --block-spacing-horizontal: calc(var(--spacing) * 1.5);
  }
}

a {
  --text-decoration: none;
}

a.contrast, a.secondary {
  --text-decoration: underline;
}

small {
  --font-size: .875em;
}

h1, h2, h3, h4, h5, h6 {
  --font-weight: 700;
}

h1 {
  --font-size: 2rem;
  --typography-spacing-vertical: 3rem;
}

h2 {
  --font-size: 1.75rem;
  --typography-spacing-vertical: 2.625rem;
}

h3 {
  --font-size: 1.5rem;
  --typography-spacing-vertical: 2.25rem;
}

h4 {
  --font-size: 1.25rem;
  --typography-spacing-vertical: 1.874rem;
}

h5 {
  --font-size: 1.125rem;
  --typography-spacing-vertical: 1.6875rem;
}

[type="checkbox"], [type="radio"] {
  --border-width: 2px;
}

[type="checkbox"][role="switch"], tfoot td, tfoot th, thead td, thead th {
  --border-width: 3px;
}

:not(thead, tfoot) > * > td {
  --font-size: .875em;
}

code, kbd, pre, samp {
  --font-family: "Menlo", "Consolas", "Roboto Mono", "Ubuntu Monospace", "Noto Mono", "Oxygen Mono", "Liberation Mono", monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

kbd {
  --font-weight: bolder;
}

:root:not([data-theme="dark"]), [data-theme="light"] {
  --background-color: #fff;
  --color: #415462;
  --h1-color: #1b2832;
  --h2-color: #24333e;
  --h3-color: #2c3d49;
  --h4-color: #374956;
  --h5-color: #415462;
  --h6-color: #4d606d;
  --muted-color: #73828c;
  --muted-border-color: #edf0f3;
  --primary: #1095c1;
  --primary-hover: #08769b;
  --primary-focus: #1095c120;
  --primary-inverse: #fff;
  --secondary: #596b78;
  --secondary-hover: #415462;
  --secondary-focus: #596b7820;
  --secondary-inverse: #fff;
  --contrast: #1b2832;
  --contrast-hover: #000;
  --contrast-focus: #596b7820;
  --contrast-inverse: #fff;
  --mark-background-color: #fff2ca;
  --mark-color: #543a26;
  --ins-color: #388e3c;
  --del-color: #c62828;
  --blockquote-border-color: var(--muted-border-color);
  --blockquote-footer-color: var(--muted-color);
  --button-box-shadow: 0 0 0 #0000;
  --button-hover-box-shadow: 0 0 0 #0000;
  --form-element-background-color: transparent;
  --form-element-border-color: #a2afb9;
  --form-element-color: var(--color);
  --form-element-placeholder-color: var(--muted-color);
  --form-element-active-background-color: transparent;
  --form-element-active-border-color: var(--primary);
  --form-element-focus-color: var(--primary-focus);
  --form-element-disabled-background-color: #d5dce2;
  --form-element-disabled-border-color: #a2afb9;
  --form-element-disabled-opacity: .5;
  --form-element-invalid-border-color: #c62828;
  --form-element-invalid-active-border-color: #d32f2f;
  --form-element-invalid-focus-color: #d32f2f20;
  --form-element-valid-border-color: #388e3c;
  --form-element-valid-active-border-color: #43a047;
  --form-element-valid-focus-color: #43a04720;
  --switch-background-color: #bbc6ce;
  --switch-color: var(--primary-inverse);
  --switch-checked-background-color: var(--primary);
  --range-border-color: #d5dce2;
  --range-active-border-color: #bbc6ce;
  --range-thumb-border-color: var(--background-color);
  --range-thumb-color: var(--secondary);
  --range-thumb-hover-color: var(--secondary-hover);
  --range-thumb-active-color: var(--primary);
  --table-border-color: var(--muted-border-color);
  --table-row-stripped-background-color: #f6f8f9;
  --code-background-color: #edf0f3;
  --code-color: var(--muted-color);
  --code-kbd-background-color: var(--contrast);
  --code-kbd-color: var(--contrast-inverse);
  --code-tag-color: #b34d80;
  --code-property-color: #3d888f;
  --code-value-color: #986;
  --code-comment-color: #a2afb9;
  --accordion-border-color: var(--muted-border-color);
  --accordion-close-summary-color: var(--color);
  --accordion-open-summary-color: var(--muted-color);
  --card-background-color: var(--background-color);
  --card-border-color: var(--muted-border-color);
  --card-box-shadow: .0145rem .029rem .174rem #1b283204, .0335rem .067rem .402rem #1b283206, .0625rem .125rem .75rem #1b283208, .1125rem .225rem 1.35rem #1b283209, .2085rem .417rem 2.502rem #1b28320b, .5rem 1rem 6rem #1b28320f, 0 0 0 .0625rem #1b283204;
  --card-sectionning-background-color: #fbfbfc;
  --dropdown-background-color: #fbfbfc;
  --dropdown-border-color: #e1e6eb;
  --dropdown-box-shadow: var(--card-box-shadow);
  --dropdown-color: var(--color);
  --dropdown-hover-background-color: #edf0f3;
  --modal-overlay-background-color: #d5dce2b3;
  --progress-background-color: #d5dce2;
  --progress-color: var(--primary);
  --loading-spinner-opacity: .5;
  --tooltip-background-color: var(--contrast);
  --tooltip-color: var(--contrast-inverse);
  --icon-checkbox: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(65, 84, 98)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron-button: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron-button-inverse: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(115, 130, 140)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  --icon-date: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(65, 84, 98)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
  --icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(198, 40, 40)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
  --icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
  --icon-search: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(65, 84, 98)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  --icon-time: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(65, 84, 98)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 6 12 12 16 14'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(56, 142, 60)' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  color-scheme: light;
}

@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme]) {
    --background-color: #11191f;
    --color: #bbc6ce;
    --h1-color: #edf0f3;
    --h2-color: #e1e6eb;
    --h3-color: #d5dce2;
    --h4-color: #c8d1d8;
    --h5-color: #bbc6ce;
    --h6-color: #afbbc4;
    --muted-color: #73828c;
    --muted-border-color: #1f2d38;
    --primary: #1095c1;
    --primary-hover: #1ab3e6;
    --primary-focus: #1095c140;
    --primary-inverse: #fff;
    --secondary: #596b78;
    --secondary-hover: #73828c;
    --secondary-focus: #73828c40;
    --secondary-inverse: #fff;
    --contrast: #edf0f3;
    --contrast-hover: #fff;
    --contrast-focus: #73828c40;
    --contrast-inverse: #000;
    --mark-background-color: #d1c284;
    --mark-color: #11191f;
    --ins-color: #388e3c;
    --del-color: #c62828;
    --blockquote-border-color: var(--muted-border-color);
    --blockquote-footer-color: var(--muted-color);
    --button-box-shadow: 0 0 0 #0000;
    --button-hover-box-shadow: 0 0 0 #0000;
    --form-element-background-color: #11191f;
    --form-element-border-color: #374956;
    --form-element-color: var(--color);
    --form-element-placeholder-color: var(--muted-color);
    --form-element-active-background-color: var(--form-element-background-color);
    --form-element-active-border-color: var(--primary);
    --form-element-focus-color: var(--primary-focus);
    --form-element-disabled-background-color: #2c3d49;
    --form-element-disabled-border-color: #415462;
    --form-element-disabled-opacity: .5;
    --form-element-invalid-border-color: #b71c1c;
    --form-element-invalid-active-border-color: #c62828;
    --form-element-invalid-focus-color: #c6282840;
    --form-element-valid-border-color: #2e7d32;
    --form-element-valid-active-border-color: #388e3c;
    --form-element-valid-focus-color: #388e3c40;
    --switch-background-color: #374956;
    --switch-color: var(--primary-inverse);
    --switch-checked-background-color: var(--primary);
    --range-border-color: #24333e;
    --range-active-border-color: #2c3d49;
    --range-thumb-border-color: var(--background-color);
    --range-thumb-color: var(--secondary);
    --range-thumb-hover-color: var(--secondary-hover);
    --range-thumb-active-color: var(--primary);
    --table-border-color: var(--muted-border-color);
    --table-row-stripped-background-color: #73828c0d;
    --code-background-color: #18232c;
    --code-color: var(--muted-color);
    --code-kbd-background-color: var(--contrast);
    --code-kbd-color: var(--contrast-inverse);
    --code-tag-color: #a65980;
    --code-property-color: #599fa6;
    --code-value-color: #8c8473;
    --code-comment-color: #4d606d;
    --accordion-border-color: var(--muted-border-color);
    --accordion-active-summary-color: var(--primary);
    --accordion-close-summary-color: var(--color);
    --accordion-open-summary-color: var(--muted-color);
    --card-background-color: #141e26;
    --card-border-color: var(--card-background-color);
    --card-box-shadow: .0145rem .029rem .174rem #00000004, .0335rem .067rem .402rem #00000006, .0625rem .125rem .75rem #00000008, .1125rem .225rem 1.35rem #00000009, .2085rem .417rem 2.502rem #0000000b, .5rem 1rem 6rem #0000000f, 0 0 0 .0625rem #00000004;
    --card-sectionning-background-color: #18232c;
    --dropdown-background-color: #1b2832;
    --dropdown-border-color: #24333e;
    --dropdown-box-shadow: var(--card-box-shadow);
    --dropdown-color: var(--color);
    --dropdown-hover-background-color: #24333ebf;
    --modal-overlay-background-color: #24333ecc;
    --progress-background-color: #24333e;
    --progress-color: var(--primary);
    --loading-spinner-opacity: .5;
    --tooltip-background-color: var(--contrast);
    --tooltip-color: var(--contrast-inverse);
    --icon-checkbox: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    --icon-chevron: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(162, 175, 185)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    --icon-chevron-button: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    --icon-chevron-button-inverse: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(0, 0, 0)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    --icon-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(115, 130, 140)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
    --icon-date: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(162, 175, 185)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
    --icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(183, 28, 28)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
    --icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
    --icon-search: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(162, 175, 185)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
    --icon-time: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(162, 175, 185)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 6 12 12 16 14'%3E%3C/polyline%3E%3C/svg%3E");
    --icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(46, 125, 50)' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    color-scheme: dark;
  }
}

[data-theme="dark"] {
  --background-color: #11191f;
  --color: #bbc6ce;
  --h1-color: #edf0f3;
  --h2-color: #e1e6eb;
  --h3-color: #d5dce2;
  --h4-color: #c8d1d8;
  --h5-color: #bbc6ce;
  --h6-color: #afbbc4;
  --muted-color: #73828c;
  --muted-border-color: #1f2d38;
  --primary: #1095c1;
  --primary-hover: #1ab3e6;
  --primary-focus: #1095c140;
  --primary-inverse: #fff;
  --secondary: #596b78;
  --secondary-hover: #73828c;
  --secondary-focus: #73828c40;
  --secondary-inverse: #fff;
  --contrast: #edf0f3;
  --contrast-hover: #fff;
  --contrast-focus: #73828c40;
  --contrast-inverse: #000;
  --mark-background-color: #d1c284;
  --mark-color: #11191f;
  --ins-color: #388e3c;
  --del-color: #c62828;
  --blockquote-border-color: var(--muted-border-color);
  --blockquote-footer-color: var(--muted-color);
  --button-box-shadow: 0 0 0 #0000;
  --button-hover-box-shadow: 0 0 0 #0000;
  --form-element-background-color: #11191f;
  --form-element-border-color: #374956;
  --form-element-color: var(--color);
  --form-element-placeholder-color: var(--muted-color);
  --form-element-active-background-color: var(--form-element-background-color);
  --form-element-active-border-color: var(--primary);
  --form-element-focus-color: var(--primary-focus);
  --form-element-disabled-background-color: #2c3d49;
  --form-element-disabled-border-color: #415462;
  --form-element-disabled-opacity: .5;
  --form-element-invalid-border-color: #b71c1c;
  --form-element-invalid-active-border-color: #c62828;
  --form-element-invalid-focus-color: #c6282840;
  --form-element-valid-border-color: #2e7d32;
  --form-element-valid-active-border-color: #388e3c;
  --form-element-valid-focus-color: #388e3c40;
  --switch-background-color: #374956;
  --switch-color: var(--primary-inverse);
  --switch-checked-background-color: var(--primary);
  --range-border-color: #24333e;
  --range-active-border-color: #2c3d49;
  --range-thumb-border-color: var(--background-color);
  --range-thumb-color: var(--secondary);
  --range-thumb-hover-color: var(--secondary-hover);
  --range-thumb-active-color: var(--primary);
  --table-border-color: var(--muted-border-color);
  --table-row-stripped-background-color: #73828c0d;
  --code-background-color: #18232c;
  --code-color: var(--muted-color);
  --code-kbd-background-color: var(--contrast);
  --code-kbd-color: var(--contrast-inverse);
  --code-tag-color: #a65980;
  --code-property-color: #599fa6;
  --code-value-color: #8c8473;
  --code-comment-color: #4d606d;
  --accordion-border-color: var(--muted-border-color);
  --accordion-active-summary-color: var(--primary);
  --accordion-close-summary-color: var(--color);
  --accordion-open-summary-color: var(--muted-color);
  --card-background-color: #141e26;
  --card-border-color: var(--card-background-color);
  --card-box-shadow: .0145rem .029rem .174rem #00000004, .0335rem .067rem .402rem #00000006, .0625rem .125rem .75rem #00000008, .1125rem .225rem 1.35rem #00000009, .2085rem .417rem 2.502rem #0000000b, .5rem 1rem 6rem #0000000f, 0 0 0 .0625rem #00000004;
  --card-sectionning-background-color: #18232c;
  --dropdown-background-color: #1b2832;
  --dropdown-border-color: #24333e;
  --dropdown-box-shadow: var(--card-box-shadow);
  --dropdown-color: var(--color);
  --dropdown-hover-background-color: #24333ebf;
  --modal-overlay-background-color: #24333ecc;
  --progress-background-color: #24333e;
  --progress-color: var(--primary);
  --loading-spinner-opacity: .5;
  --tooltip-background-color: var(--contrast);
  --tooltip-color: var(--contrast-inverse);
  --icon-checkbox: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(162, 175, 185)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron-button: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-chevron-button-inverse: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(0, 0, 0)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(115, 130, 140)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  --icon-date: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(162, 175, 185)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
  --icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(183, 28, 28)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
  --icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
  --icon-search: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(162, 175, 185)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  --icon-time: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(162, 175, 185)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 6 12 12 16 14'%3E%3C/polyline%3E%3C/svg%3E");
  --icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(46, 125, 50)' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  color-scheme: dark;
}

[type="checkbox"], [type="radio"], [type="range"], progress {
  accent-color: var(--primary);
}

*, :after, :before {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

:after, :before {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

:where(:root) {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
  background-color: var(--background-color);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-family: var(--font-family);
  text-rendering: optimizelegibility;
  overflow-wrap: break-word;
  cursor: default;
  tab-size: 4;
}

main {
  display: block;
}

body {
  width: 100%;
  margin: 0;
}

body > footer, body > header, body > main {
  padding: var(--block-spacing-vertical) 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.container, .container-fluid {
  padding-right: var(--spacing);
  padding-left: var(--spacing);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 510px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 700px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 920px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1130px;
  }
}

section {
  margin-bottom: var(--block-spacing-vertical);
}

.grid {
  grid-column-gap: var(--grid-spacing-horizontal);
  grid-row-gap: var(--grid-spacing-vertical);
  grid-template-columns: 1fr;
  margin: 0;
  display: grid;
}

@media (min-width: 992px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(0%, 1fr));
  }
}

.grid > * {
  min-width: 0;
}

figure {
  margin: 0;
  padding: 0;
  display: block;
  overflow-x: auto;
}

figure figcaption {
  padding: calc(var(--spacing) * .5) 0;
  color: var(--muted-color);
}

b, strong {
  font-weight: bolder;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

address, blockquote, dl, figure, form, ol, p, pre, table, ul {
  margin-top: 0;
  margin-bottom: var(--typography-spacing-vertical);
  color: var(--color);
  font-style: normal;
  font-weight: var(--font-weight);
  font-size: var(--font-size);
}

[role="link"], a {
  --color: var(--primary);
  --background-color: transparent;
  background-color: var(--background-color);
  color: var(--color);
  -webkit-text-decoration: var(--text-decoration);
  -webkit-text-decoration: var(--text-decoration);
  text-decoration: var(--text-decoration);
  transition: background-color var(--transition), color var(--transition), box-shadow var(--transition), -webkit-text-decoration var(--transition);
  transition: background-color var(--transition), color var(--transition), text-decoration var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), color var(--transition), text-decoration var(--transition), box-shadow var(--transition), -webkit-text-decoration var(--transition);
  outline: 0;
}

[role="link"]:is([aria-current], :hover, :active, :focus), a:is([aria-current], :hover, :active, :focus) {
  --color: var(--primary-hover);
  --text-decoration: underline;
}

[role="link"]:focus, a:focus {
  --background-color: var(--primary-focus);
}

[role="link"].secondary, a.secondary {
  --color: var(--secondary);
}

[role="link"].secondary:is([aria-current], :hover, :active, :focus), a.secondary:is([aria-current], :hover, :active, :focus) {
  --color: var(--secondary-hover);
}

[role="link"].secondary:focus, a.secondary:focus {
  --background-color: var(--secondary-focus);
}

[role="link"].contrast, a.contrast {
  --color: var(--contrast);
}

[role="link"].contrast:is([aria-current], :hover, :active, :focus), a.contrast:is([aria-current], :hover, :active, :focus) {
  --color: var(--contrast-hover);
}

[role="link"].contrast:focus, a.contrast:focus {
  --background-color: var(--contrast-focus);
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: var(--typography-spacing-vertical);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  font-family: var(--font-family);
}

h1 {
  --color: var(--h1-color);
}

h2 {
  --color: var(--h2-color);
}

h3 {
  --color: var(--h3-color);
}

h4 {
  --color: var(--h4-color);
}

h5 {
  --color: var(--h5-color);
}

h6 {
  --color: var(--h6-color);
}

:where(address, blockquote, dl, figure, form, ol, p, pre, table, ul) ~ :is(h1, h2, h3, h4, h5, h6) {
  margin-top: var(--typography-spacing-vertical);
}

.headings, hgroup {
  margin-bottom: var(--typography-spacing-vertical);
}

.headings > *, hgroup > * {
  margin-bottom: 0;
}

.headings > :last-child, hgroup > :last-child {
  --color: var(--muted-color);
  --font-weight: unset;
  font-family: unset;
  font-size: 1rem;
}

p {
  margin-bottom: var(--typography-spacing-vertical);
}

small {
  font-size: var(--font-size);
}

:where(dl, ol, ul) {
  padding-right: 0;
  padding-left: var(--spacing);
  -webkit-padding-start: var(--spacing);
  -webkit-padding-end: 0;
  padding-inline-start: var(--spacing);
  padding-inline-end: 0;
}

:where(dl, ol, ul) li {
  margin-bottom: calc(var(--typography-spacing-vertical) * .25);
}

:where(dl, ol, ul) :is(dl, ol, ul) {
  margin: 0;
  margin-top: calc(var(--typography-spacing-vertical) * .25);
}

ul li {
  list-style: square;
}

mark {
  background-color: var(--mark-background-color);
  color: var(--mark-color);
  vertical-align: baseline;
  padding: .125rem .25rem;
}

blockquote {
  margin: var(--typography-spacing-vertical) 0;
  padding: var(--spacing);
  border-right: none;
  border-left: .25rem solid var(--blockquote-border-color);
  -webkit-border-start: .25rem solid var(--blockquote-border-color);
  border-inline-start: .25rem solid var(--blockquote-border-color);
  -webkit-border-end: none;
  border-inline-end: none;
  display: block;
}

blockquote footer {
  margin-top: calc(var(--typography-spacing-vertical) * .5);
  color: var(--blockquote-footer-color);
}

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted;
  text-decoration: none;
}

ins {
  color: var(--ins-color);
  text-decoration: none;
}

del {
  color: var(--del-color);
}

::selection {
  background-color: var(--primary-focus);
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

:where(iframe) {
  border-style: none;
}

img {
  border-style: none;
  max-width: 100%;
  height: auto;
}

:where(svg:not([fill])) {
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

button {
  text-transform: none;
  margin: 0;
  font-family: inherit;
  overflow: visible;
}

[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: button;
}

button {
  margin-bottom: var(--spacing);
  width: 100%;
  display: block;
}

[role="button"] {
  text-decoration: none;
  display: inline-block;
}

[role="button"], button, input[type="button"], input[type="reset"], input[type="submit"] {
  --background-color: var(--primary);
  --border-color: var(--primary);
  --color: var(--primary-inverse);
  --box-shadow: var(--button-box-shadow, 0 0 0 #0000);
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  line-height: var(--line-height);
  text-align: center;
  cursor: pointer;
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  outline: 0;
}

[role="button"]:is([aria-current], :hover, :active, :focus), button:is([aria-current], :hover, :active, :focus), input[type="button"]:is([aria-current], :hover, :active, :focus), input[type="reset"]:is([aria-current], :hover, :active, :focus), input[type="submit"]:is([aria-current], :hover, :active, :focus) {
  --background-color: var(--primary-hover);
  --border-color: var(--primary-hover);
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 #0000);
  --color: var(--primary-inverse);
}

[role="button"]:focus, button:focus, input[type="button"]:focus, input[type="reset"]:focus, input[type="submit"]:focus {
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--outline-width) var(--primary-focus);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).secondary, input[type="reset"] {
  --background-color: var(--secondary);
  --border-color: var(--secondary);
  --color: var(--secondary-inverse);
  cursor: pointer;
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).secondary:is([aria-current], :hover, :active, :focus), input[type="reset"]:is([aria-current], :hover, :active, :focus) {
  --background-color: var(--secondary-hover);
  --border-color: var(--secondary-hover);
  --color: var(--secondary-inverse);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).secondary:focus, input[type="reset"]:focus {
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--outline-width) var(--secondary-focus);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).contrast {
  --background-color: var(--contrast);
  --border-color: var(--contrast);
  --color: var(--contrast-inverse);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).contrast:is([aria-current], :hover, :active, :focus) {
  --background-color: var(--contrast-hover);
  --border-color: var(--contrast-hover);
  --color: var(--contrast-inverse);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).contrast:focus {
  --box-shadow: var(--button-hover-box-shadow, 0 0 0 #0000), 0 0 0 var(--outline-width) var(--contrast-focus);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline, input[type="reset"].outline {
  --background-color: transparent;
  --color: var(--primary);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline:is([aria-current], :hover, :active, :focus), input[type="reset"].outline:is([aria-current], :hover, :active, :focus) {
  --background-color: transparent;
  --color: var(--primary-hover);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline.secondary, input[type="reset"].outline {
  --color: var(--secondary);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline.secondary:is([aria-current], :hover, :active, :focus), input[type="reset"].outline:is([aria-current], :hover, :active, :focus) {
  --color: var(--secondary-hover);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline.contrast {
  --color: var(--contrast);
}

:is(button, input[type="submit"], input[type="button"], [role="button"]).outline.contrast:is([aria-current], :hover, :active, :focus) {
  --color: var(--contrast-hover);
}

:where(button, [type="submit"], [type="button"], [type="reset"], [role="button"])[disabled], :where(fieldset[disabled]) :is(button, [type="submit"], [type="button"], [type="reset"], [role="button"]), a[role="button"]:not([href]) {
  opacity: .5;
  pointer-events: none;
}

input, optgroup, select, textarea {
  font-size: 1rem;
  line-height: var(--line-height);
  letter-spacing: inherit;
  margin: 0;
  font-family: inherit;
}

input {
  overflow: visible;
}

select {
  text-transform: none;
}

legend {
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 0;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-ms-expand {
  display: none;
}

[type="file"], [type="range"] {
  border-width: 0;
  padding: 0;
}

input:not([type="checkbox"], [type="radio"], [type="range"]) {
  height: calc(1rem * var(--line-height)  + var(--form-element-spacing-vertical) * 2 + var(--border-width) * 2);
}

fieldset {
  margin: 0;
  margin-bottom: var(--spacing);
  border: 0;
  padding: 0;
}

fieldset legend, label {
  margin-bottom: calc(var(--spacing) * .25);
  font-weight: var(--form-label-font-weight, var(--font-weight));
  display: block;
}

input:not([type="checkbox"], [type="radio"]), select, textarea {
  width: 100%;
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"]), select, textarea {
  -webkit-appearance: none;
  appearance: none;
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
}

input, select, textarea {
  --background-color: var(--form-element-background-color);
  --border-color: var(--form-element-border-color);
  --color: var(--form-element-color);
  --box-shadow: none;
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  outline: 0;
}

:where(select, textarea):is(:active, :focus), input:not([type="submit"], [type="button"], [type="reset"], [type="checkbox"], [type="radio"], [readonly]):is(:active, :focus) {
  --background-color: var(--form-element-active-background-color);
}

:where(select, textarea):is(:active, :focus), input:not([type="submit"], [type="button"], [type="reset"], [role="switch"], [readonly]):is(:active, :focus) {
  --border-color: var(--form-element-active-border-color);
}

input:not([type="submit"], [type="button"], [type="reset"], [type="range"], [type="file"], [readonly]):focus, select:focus, textarea:focus {
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-focus-color);
}

:where(fieldset[disabled]) :is(input:not([type="submit"], [type="button"], [type="reset"]), select, textarea), input:not([type="submit"], [type="button"], [type="reset"])[disabled], select[disabled], textarea[disabled] {
  --background-color: var(--form-element-disabled-background-color);
  --border-color: var(--form-element-disabled-border-color);
  opacity: var(--form-element-disabled-opacity);
  pointer-events: none;
}

:where(input, select, textarea):not([type="checkbox"], [type="radio"], [type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"])[aria-invalid] {
  padding-left: var(--form-element-spacing-horizontal);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  padding-right: calc(var(--form-element-spacing-horizontal)  + 1.5rem) !important;
  -webkit-padding-start: var(--form-element-spacing-horizontal) !important;
  -webkit-padding-end: calc(var(--form-element-spacing-horizontal)  + 1.5rem) !important;
  padding-inline-start: var(--form-element-spacing-horizontal) !important;
  padding-inline-end: calc(var(--form-element-spacing-horizontal)  + 1.5rem) !important;
}

:where(input, select, textarea):not([type="checkbox"], [type="radio"], [type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"])[aria-invalid="false"] {
  background-image: var(--icon-valid);
}

:where(input, select, textarea):not([type="checkbox"], [type="radio"], [type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"])[aria-invalid="true"] {
  background-image: var(--icon-invalid);
}

:where(input, select, textarea)[aria-invalid="false"] {
  --border-color: var(--form-element-valid-border-color);
}

:where(input, select, textarea)[aria-invalid="false"]:is(:active, :focus) {
  --border-color: var(--form-element-valid-active-border-color) !important;
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-valid-focus-color) !important;
}

:where(input, select, textarea)[aria-invalid="true"] {
  --border-color: var(--form-element-invalid-border-color);
}

:where(input, select, textarea)[aria-invalid="true"]:is(:active, :focus) {
  --border-color: var(--form-element-invalid-active-border-color) !important;
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-invalid-focus-color) !important;
}

[dir="rtl"] :where(input, select, textarea):not([type="checkbox"], [type="radio"]):is([aria-invalid], [aria-invalid="true"], [aria-invalid="false"]) {
  background-position: .75rem 50%;
}

input::placeholder, select:invalid, textarea::placeholder {
  color: var(--form-element-placeholder-color);
  opacity: 1;
}

input::-webkit-input-placeholder {
  color: var(--form-element-placeholder-color);
  opacity: 1;
}

textarea::-webkit-input-placeholder {
  color: var(--form-element-placeholder-color);
  opacity: 1;
}

input:not([type="checkbox"], [type="radio"]), select, textarea {
  margin-bottom: var(--spacing);
}

select::-ms-expand {
  background-color: #0000;
  border: 0;
}

select:not([multiple], [size]) {
  padding-right: calc(var(--form-element-spacing-horizontal)  + 1.5rem);
  padding-left: var(--form-element-spacing-horizontal);
  -webkit-padding-start: var(--form-element-spacing-horizontal);
  -webkit-padding-end: calc(var(--form-element-spacing-horizontal)  + 1.5rem);
  background-image: var(--icon-chevron);
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  padding-inline-start: var(--form-element-spacing-horizontal);
  padding-inline-end: calc(var(--form-element-spacing-horizontal)  + 1.5rem);
}

[dir="rtl"] select:not([multiple], [size]) {
  background-position: .75rem 50%;
}

:where(input, select, textarea, .grid) + small {
  margin-top: calc(var(--spacing) * -.75);
  margin-bottom: var(--spacing);
  color: var(--muted-color);
  width: 100%;
  display: block;
}

label > :where(input, select, textarea) {
  margin-top: calc(var(--spacing) * .25);
}

[type="checkbox"], [type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  -webkit-margin-start: 0;
  margin-top: -.125em;
  margin-left: 0;
  margin-right: .375em;
  -webkit-margin-end: .375em;
  border-width: var(--border-width);
  font-size: inherit;
  vertical-align: middle;
  cursor: pointer;
  width: 1.25em;
  height: 1.25em;
  margin-inline: 0 .375em;
}

[type="checkbox"]::-ms-check {
  display: none;
}

[type="radio"]::-ms-check {
  display: none;
}

[type="checkbox"]:checked, [type="checkbox"]:checked:active, [type="checkbox"]:checked:focus, [type="radio"]:checked, [type="radio"]:checked:active, [type="radio"]:checked:focus {
  --background-color: var(--primary);
  --border-color: var(--primary);
  background-image: var(--icon-checkbox);
  background-position: center;
  background-repeat: no-repeat;
  background-size: .75em;
}

[type="checkbox"] ~ label, [type="radio"] ~ label {
  cursor: pointer;
  margin-bottom: 0;
  margin-right: .375em;
  display: inline-block;
}

[type="checkbox"]:indeterminate {
  --background-color: var(--primary);
  --border-color: var(--primary);
  background-image: var(--icon-minus);
  background-position: center;
  background-repeat: no-repeat;
  background-size: .75em;
}

[type="radio"] {
  border-radius: 50%;
}

[type="radio"]:checked, [type="radio"]:checked:active, [type="radio"]:checked:focus {
  --background-color: var(--primary-inverse);
  background-image: none;
  border-width: .35em;
}

[type="checkbox"][role="switch"] {
  --background-color: var(--switch-background-color);
  --border-color: var(--switch-background-color);
  --color: var(--switch-color);
  border: var(--border-width) solid var(--border-color);
  background-color: var(--background-color);
  border-radius: 1.25em;
  width: 2.25em;
  height: 1.25em;
  line-height: 1.25em;
}

[type="checkbox"][role="switch"]:focus {
  --background-color: var(--switch-background-color);
  --border-color: var(--switch-background-color);
}

[type="checkbox"][role="switch"]:checked {
  --background-color: var(--switch-checked-background-color);
  --border-color: var(--switch-checked-background-color);
}

[type="checkbox"][role="switch"]:before {
  width: calc(1.25em - (var(--border-width) * 2));
  background-color: var(--color);
  content: "";
  border-radius: 50%;
  height: 100%;
  transition: margin .1s ease-in-out;
  display: block;
}

[type="checkbox"][role="switch"]:checked {
  background-image: none;
}

[type="checkbox"][role="switch"]:checked:before {
  margin-left: calc(1.125em - var(--border-width));
  -webkit-margin-start: calc(1.125em - var(--border-width));
  margin-inline-start: calc(1.125em - var(--border-width));
}

[type="checkbox"]:checked[aria-invalid="false"], [type="checkbox"][aria-invalid="false"], [type="checkbox"][role="switch"]:checked[aria-invalid="false"], [type="checkbox"][role="switch"][aria-invalid="false"], [type="radio"]:checked[aria-invalid="false"], [type="radio"][aria-invalid="false"] {
  --border-color: var(--form-element-valid-border-color);
}

[type="checkbox"]:checked[aria-invalid="true"], [type="checkbox"][aria-invalid="true"], [type="checkbox"][role="switch"]:checked[aria-invalid="true"], [type="checkbox"][role="switch"][aria-invalid="true"], [type="radio"]:checked[aria-invalid="true"], [type="radio"][aria-invalid="true"] {
  --border-color: var(--form-element-invalid-border-color);
}

[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

[type="color"]::-moz-focus-inner {
  padding: 0;
}

[type="color"]::-webkit-color-swatch {
  border-radius: calc(var(--border-radius) * .5);
  border: 0;
}

[type="color"]::-moz-color-swatch {
  border-radius: calc(var(--border-radius) * .5);
  border: 0;
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"]):is([type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"]) {
  --icon-position: .75rem;
  --icon-width: 1rem;
  padding-right: calc(var(--icon-width)  + var(--icon-position));
  background-image: var(--icon-date);
  background-position: center right var(--icon-position);
  background-size: var(--icon-width) auto;
  background-repeat: no-repeat;
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="time"] {
  background-image: var(--icon-time);
}

[type="date"]::-webkit-calendar-picker-indicator {
  width: var(--icon-width);
  margin-right: calc(var(--icon-width) * -1);
  margin-left: var(--icon-position);
  opacity: 0;
}

[type="datetime-local"]::-webkit-calendar-picker-indicator {
  width: var(--icon-width);
  margin-right: calc(var(--icon-width) * -1);
  margin-left: var(--icon-position);
  opacity: 0;
}

[type="month"]::-webkit-calendar-picker-indicator {
  width: var(--icon-width);
  margin-right: calc(var(--icon-width) * -1);
  margin-left: var(--icon-position);
  opacity: 0;
}

[type="time"]::-webkit-calendar-picker-indicator {
  width: var(--icon-width);
  margin-right: calc(var(--icon-width) * -1);
  margin-left: var(--icon-position);
  opacity: 0;
}

[type="week"]::-webkit-calendar-picker-indicator {
  width: var(--icon-width);
  margin-right: calc(var(--icon-width) * -1);
  margin-left: var(--icon-position);
  opacity: 0;
}

[dir="rtl"] :is([type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"]) {
  text-align: right;
}

@-moz-document url-prefix() {
  [type="date"], [type="datetime-local"], [type="month"], [type="time"], [type="week"] {
    padding-right: var(--form-element-spacing-horizontal) !important;
    background-image: none !important;
  }
}

[type="file"] {
  --color: var(--muted-color);
  padding: calc(var(--form-element-spacing-vertical) * .5) 0;
  background: none;
  border: 0;
  border-radius: 0;
}

[type="file"]::file-selector-button {
  --background-color: var(--secondary);
  --border-color: var(--secondary);
  --color: var(--secondary-inverse);
  margin-right: calc(var(--spacing) / 2);
  -webkit-margin-start: 0;
  margin-left: 0;
  -webkit-margin-end: calc(var(--spacing) / 2);
  padding: calc(var(--form-element-spacing-vertical) * .5) calc(var(--form-element-spacing-horizontal) * .5);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  line-height: var(--line-height);
  text-align: center;
  cursor: pointer;
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  outline: 0;
  margin-inline-start: 0;
  margin-inline-end: calc(var(--spacing) / 2);
}

[type="file"]::file-selector-button:is(:hover, :active, :focus) {
  --background-color: var(--secondary-hover);
  --border-color: var(--secondary-hover);
}

[type="file"]::-webkit-file-upload-button {
  --background-color: var(--secondary);
  --border-color: var(--secondary);
  --color: var(--secondary-inverse);
  margin-right: calc(var(--spacing) / 2);
  -webkit-margin-start: 0;
  margin-left: 0;
  -webkit-margin-end: calc(var(--spacing) / 2);
  padding: calc(var(--form-element-spacing-vertical) * .5) calc(var(--form-element-spacing-horizontal) * .5);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  line-height: var(--line-height);
  text-align: center;
  cursor: pointer;
  -webkit-transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  outline: 0;
  margin-inline-start: 0;
  margin-inline-end: calc(var(--spacing) / 2);
}

[type="file"]::-webkit-file-upload-button:is(:hover, :active, :focus) {
  --background-color: var(--secondary-hover);
  --border-color: var(--secondary-hover);
}

[type="file"]::-ms-browse {
  --background-color: var(--secondary);
  --border-color: var(--secondary);
  --color: var(--secondary-inverse);
  margin-right: calc(var(--spacing) / 2);
  margin-left: 0;
  padding: calc(var(--form-element-spacing-vertical) * .5) calc(var(--form-element-spacing-horizontal) * .5);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  box-shadow: var(--box-shadow);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: 1rem;
  line-height: var(--line-height);
  text-align: center;
  cursor: pointer;
  -ms-transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
  outline: 0;
  margin-inline-start: 0;
  margin-inline-end: calc(var(--spacing) / 2);
}

[type="file"]::-ms-browse:is(:hover, :active, :focus) {
  --background-color: var(--secondary-hover);
  --border-color: var(--secondary-hover);
}

[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  width: 100%;
  height: 1.25rem;
}

[type="range"]::-webkit-slider-runnable-track {
  border-radius: var(--border-radius);
  background-color: var(--range-border-color);
  -webkit-transition: background-color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), box-shadow var(--transition);
  width: 100%;
  height: .25rem;
}

[type="range"]::-moz-range-track {
  border-radius: var(--border-radius);
  background-color: var(--range-border-color);
  -moz-transition: background-color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), box-shadow var(--transition);
  width: 100%;
  height: .25rem;
}

[type="range"]::-ms-track {
  border-radius: var(--border-radius);
  background-color: var(--range-border-color);
  -ms-transition: background-color var(--transition), box-shadow var(--transition);
  transition: background-color var(--transition), box-shadow var(--transition);
  width: 100%;
  height: .25rem;
}

[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 2px solid var(--range-thumb-border-color);
  background-color: var(--range-thumb-color);
  cursor: pointer;
  -webkit-transition: background-color var(--transition), transform var(--transition);
  transition: background-color var(--transition), transform var(--transition);
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -.5rem;
}

[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  border: 2px solid var(--range-thumb-border-color);
  background-color: var(--range-thumb-color);
  cursor: pointer;
  -moz-transition: background-color var(--transition), transform var(--transition);
  transition: background-color var(--transition), transform var(--transition);
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -.5rem;
}

[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  border: 2px solid var(--range-thumb-border-color);
  background-color: var(--range-thumb-color);
  cursor: pointer;
  -ms-transition: background-color var(--transition), transform var(--transition);
  transition: background-color var(--transition), transform var(--transition);
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -.5rem;
}

[type="range"]:focus, [type="range"]:hover {
  --range-border-color: var(--range-active-border-color);
  --range-thumb-color: var(--range-thumb-hover-color);
}

[type="range"]:active {
  --range-thumb-color: var(--range-thumb-active-color);
}

[type="range"]:active::-webkit-slider-thumb {
  transform: scale(1.25);
}

[type="range"]:active::-moz-range-thumb {
  transform: scale(1.25);
}

[type="range"]:active::-ms-thumb {
  transform: scale(1.25);
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"] {
  -webkit-padding-start: calc(var(--form-element-spacing-horizontal)  + 1.75rem);
  background-image: var(--icon-search);
  background-position: 1.125rem 50%;
  background-repeat: no-repeat;
  background-size: 1rem;
  border-radius: 5rem;
  padding-inline-start: calc(var(--form-element-spacing-horizontal)  + 1.75rem);
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"][aria-invalid] {
  background-position: 1.125rem 50%, right .75rem center;
  -webkit-padding-start: calc(var(--form-element-spacing-horizontal)  + 1.75rem) !important;
  padding-inline-start: calc(var(--form-element-spacing-horizontal)  + 1.75rem) !important;
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"][aria-invalid="false"] {
  background-image: var(--icon-search), var(--icon-valid);
}

input:not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"][aria-invalid="true"] {
  background-image: var(--icon-search), var(--icon-invalid);
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}

[dir="rtl"] :where(input):not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"] {
  background-position: right 1.125rem center;
}

[dir="rtl"] :where(input):not([type="checkbox"], [type="radio"], [type="range"], [type="file"])[type="search"][aria-invalid] {
  background-position: right 1.125rem center, .75rem 50%;
}

:where(table) {
  border-collapse: collapse;
  border-spacing: 0;
  text-indent: 0;
  width: 100%;
}

td, th {
  padding: calc(var(--spacing) / 2) var(--spacing);
  border-bottom: var(--border-width) solid var(--table-border-color);
  color: var(--color);
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  text-align: left;
  text-align: start;
}

tfoot td, tfoot th {
  border-top: var(--border-width) solid var(--table-border-color);
  border-bottom: 0;
}

table[role="grid"] tbody tr:nth-child(odd) {
  background-color: var(--table-row-stripped-background-color);
}

code, kbd, pre, samp {
  font-size: .875em;
  font-family: var(--font-family);
}

pre {
  -ms-overflow-style: scrollbar;
  overflow: auto;
}

code, kbd, pre {
  border-radius: var(--border-radius);
  background: var(--code-background-color);
  color: var(--code-color);
  font-weight: var(--font-weight);
  line-height: initial;
}

code, kbd {
  padding: .375rem .5rem;
  display: inline-block;
}

pre {
  margin-bottom: var(--spacing);
  display: block;
  overflow-x: auto;
}

pre > code {
  padding: var(--spacing);
  font-size: 14px;
  line-height: var(--line-height);
  background: none;
  display: block;
}

code b {
  color: var(--code-tag-color);
  font-weight: var(--font-weight);
}

code i {
  color: var(--code-property-color);
  font-style: normal;
}

code u {
  color: var(--code-value-color);
  text-decoration: none;
}

code em {
  color: var(--code-comment-color);
  font-style: normal;
}

kbd {
  background-color: var(--code-kbd-background-color);
  color: var(--code-kbd-color);
  vertical-align: baseline;
}

hr {
  border: 0;
  border-top: 1px solid var(--muted-border-color);
  color: inherit;
  height: 0;
}

[hidden], template {
  display: none !important;
}

canvas {
  display: inline-block;
}

details {
  margin-bottom: var(--spacing);
  padding-bottom: var(--spacing);
  border-bottom: var(--border-width) solid var(--accordion-border-color);
  display: block;
}

details summary {
  cursor: pointer;
  transition: color var(--transition);
  line-height: 1rem;
  list-style-type: none;
}

details summary:not([role]) {
  color: var(--accordion-close-summary-color);
}

details summary::-webkit-details-marker {
  display: none;
}

details summary::marker {
  display: none;
}

details summary::-moz-list-bullet {
  list-style-type: none;
}

details summary:after {
  -webkit-margin-start: calc(var(--spacing, 1rem) * .5);
  float: right;
  background-image: var(--icon-chevron);
  content: "";
  transition: transform var(--transition);
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 1rem;
  width: 1rem;
  height: 1rem;
  margin-inline-start: calc(var(--spacing, 1rem) * .5);
  display: block;
  transform: rotate(-90deg);
}

details summary:focus {
  outline: 0;
}

details summary:focus:not([role="button"]) {
  color: var(--accordion-active-summary-color);
}

details summary[role="button"] {
  text-align: left;
  width: 100%;
}

details summary[role="button"]:after {
  height: calc(1rem * var(--line-height, 1.5));
  background-image: var(--icon-chevron-button);
}

details summary[role="button"]:not(.outline).contrast:after {
  background-image: var(--icon-chevron-button-inverse);
}

details[open] > summary {
  margin-bottom: calc(var(--spacing));
}

details[open] > summary:not([role]):not(:focus) {
  color: var(--accordion-open-summary-color);
}

details[open] > summary:after {
  transform: rotate(0);
}

[dir="rtl"] details summary {
  text-align: right;
}

[dir="rtl"] details summary:after {
  float: left;
  background-position: 0;
}

article {
  margin: var(--block-spacing-vertical) 0;
  padding: var(--block-spacing-vertical) var(--block-spacing-horizontal);
  border-radius: var(--border-radius);
  background: var(--card-background-color);
  box-shadow: var(--card-box-shadow);
}

article > footer, article > header {
  margin-right: calc(var(--block-spacing-horizontal) * -1);
  margin-left: calc(var(--block-spacing-horizontal) * -1);
  padding: calc(var(--block-spacing-vertical) * .66) var(--block-spacing-horizontal);
  background-color: var(--card-sectionning-background-color);
}

article > header {
  margin-top: calc(var(--block-spacing-vertical) * -1);
  margin-bottom: var(--block-spacing-vertical);
  border-bottom: var(--border-width) solid var(--card-border-color);
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}

article > footer {
  margin-top: var(--block-spacing-vertical);
  margin-bottom: calc(var(--block-spacing-vertical) * -1);
  border-top: var(--border-width) solid var(--card-border-color);
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

:root {
  --scrollbar-width: 0px;
}

dialog {
  z-index: 999;
  width: inherit;
  height: inherit;
  padding: var(--spacing);
  -webkit-backdrop-filter: var(--modal-overlay-backdrop-filter);
  backdrop-filter: var(--modal-overlay-backdrop-filter);
  background-color: var(--modal-overlay-background-color);
  color: var(--color);
  border: 0;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  position: fixed;
  inset: 0;
}

dialog article {
  max-height: calc(100vh - var(--spacing) * 2);
  overflow: auto;
}

@media (min-width: 576px) {
  dialog article {
    max-width: 510px;
  }
}

@media (min-width: 768px) {
  dialog article {
    max-width: 700px;
  }
}

dialog article > footer, dialog article > header {
  padding: calc(var(--block-spacing-vertical) * .5) var(--block-spacing-horizontal);
}

dialog article > header .close {
  margin: 0;
  margin-left: var(--spacing);
  float: right;
}

dialog article > footer {
  text-align: right;
}

dialog article > footer [role="button"] {
  margin-bottom: 0;
}

dialog article > footer [role="button"]:not(:first-of-type) {
  margin-left: calc(var(--spacing) * .5);
}

dialog article p:last-of-type {
  margin: 0;
}

dialog article .close {
  margin-top: calc(var(--block-spacing-vertical) * -.5);
  margin-bottom: var(--typography-spacing-vertical);
  background-image: var(--icon-close);
  opacity: .5;
  transition: opacity var(--transition);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 1rem;
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  display: block;
}

dialog article .close:is([aria-current], :hover, :active, :focus) {
  opacity: 1;
}

dialog:not([open]), dialog[open="false"] {
  display: none;
}

.modal-is-open {
  padding-right: var(--scrollbar-width, 0);
  pointer-events: none;
  touch-action: none;
  overflow: hidden;
}

.modal-is-open dialog {
  pointer-events: auto;
}

:where(.modal-is-opening, .modal-is-closing) dialog, :where(.modal-is-opening, .modal-is-closing) dialog > article {
  animation-duration: .2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
}

:where(.modal-is-opening, .modal-is-closing) dialog {
  animation-name: modal-overlay;
  animation-duration: .8s;
}

:where(.modal-is-opening, .modal-is-closing) dialog > article {
  animation-name: modal;
  animation-delay: .2s;
}

.modal-is-closing dialog, .modal-is-closing dialog > article {
  animation-direction: reverse;
  animation-delay: 0s;
}

@keyframes modal-overlay {
  from {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    background-color: #0000;
  }
}

@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
}

:where(nav li):before {
  float: left;
  content: "​";
}

nav, nav ul {
  display: flex;
}

nav {
  justify-content: space-between;
}

nav ol, nav ul {
  align-items: center;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

nav ol:first-of-type, nav ul:first-of-type {
  margin-left: calc(var(--nav-element-spacing-horizontal) * -1);
}

nav ol:last-of-type, nav ul:last-of-type {
  margin-right: calc(var(--nav-element-spacing-horizontal) * -1);
}

nav li {
  padding: var(--nav-element-spacing-vertical) var(--nav-element-spacing-horizontal);
  margin: 0;
  display: inline-block;
}

nav li > * {
  --spacing: 0;
}

nav :where(a, [role="link"]) {
  margin: calc(var(--nav-link-spacing-vertical) * -1) calc(var(--nav-link-spacing-horizontal) * -1);
  padding: var(--nav-link-spacing-vertical) var(--nav-link-spacing-horizontal);
  border-radius: var(--border-radius);
  text-decoration: none;
  display: inline-block;
}

nav :where(a, [role="link"]):is([aria-current], :hover, :active, :focus) {
  text-decoration: none;
}

nav[aria-label="breadcrumb"] {
  justify-content: start;
  align-items: center;
}

nav[aria-label="breadcrumb"] ul li:not(:first-child) {
  -webkit-margin-start: var(--nav-link-spacing-horizontal);
  margin-inline-start: var(--nav-link-spacing-horizontal);
}

nav[aria-label="breadcrumb"] ul li:not(:last-child) :after {
  width: calc(var(--nav-link-spacing-horizontal) * 2);
  -webkit-margin-start: calc(var(--nav-link-spacing-horizontal) / 2);
  content: "/";
  color: var(--muted-color);
  text-align: center;
  margin-inline-start: calc(var(--nav-link-spacing-horizontal) / 2);
  position: absolute;
}

nav[aria-label="breadcrumb"] a[aria-current] {
  color: inherit;
  pointer-events: none;
  background-color: #0000;
  text-decoration: none;
}

nav [role="button"] {
  margin-right: inherit;
  margin-left: inherit;
  padding: var(--nav-link-spacing-vertical) var(--nav-link-spacing-horizontal);
}

aside li, aside nav, aside ol, aside ul {
  display: block;
}

aside li {
  padding: calc(var(--nav-element-spacing-vertical) * .5) var(--nav-element-spacing-horizontal);
}

aside li a {
  display: block;
}

aside li [role="button"] {
  margin: inherit;
}

[dir="rtl"] nav[aria-label="breadcrumb"] ul li:not(:last-child) :after {
  content: "\\";
}

progress {
  vertical-align: baseline;
  -webkit-appearance: none;
  appearance: none;
  margin-bottom: calc(var(--spacing) * .5);
  border-radius: var(--border-radius);
  background-color: var(--progress-background-color);
  color: var(--progress-color);
  border: 0;
  width: 100%;
  height: .5rem;
  display: inline-block;
  overflow: hidden;
}

progress::-webkit-progress-bar {
  border-radius: var(--border-radius);
  background: none;
}

progress[value]::-webkit-progress-value {
  background-color: var(--progress-color);
}

progress::-moz-progress-bar {
  background-color: var(--progress-color);
}

@media (prefers-reduced-motion: no-preference) {
  progress:indeterminate {
    background: var(--progress-background-color) linear-gradient(to right, var(--progress-color) 30%, var(--progress-background-color) 30%) top left / 150% 150% no-repeat;
    animation: 1s linear infinite progress-indeterminate;
  }

  progress:indeterminate[value]::-webkit-progress-value {
    background-color: #0000;
  }

  progress:indeterminate::-moz-progress-bar {
    background-color: #0000;
  }

  [dir="rtl"] progress:indeterminate {
    animation-direction: reverse;
  }
}

@keyframes progress-indeterminate {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

details[role="list"], li[role="list"] {
  position: relative;
}

details[role="list"] summary + ul, li[role="list"] > ul {
  z-index: 99;
  border: var(--border-width) solid var(--dropdown-border-color);
  border-radius: var(--border-radius);
  background-color: var(--dropdown-background-color);
  box-shadow: var(--card-box-shadow);
  color: var(--dropdown-color);
  white-space: nowrap;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  flex-direction: column;
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
}

details[role="list"] summary + ul li, li[role="list"] > ul li {
  padding: calc(var(--form-element-spacing-vertical) * .5) var(--form-element-spacing-horizontal);
  width: 100%;
  margin-bottom: 0;
  list-style: none;
}

details[role="list"] summary + ul li:first-of-type, li[role="list"] > ul li:first-of-type {
  margin-top: calc(var(--form-element-spacing-vertical) * .5);
}

details[role="list"] summary + ul li:last-of-type, li[role="list"] > ul li:last-of-type {
  margin-bottom: calc(var(--form-element-spacing-vertical) * .5);
}

details[role="list"] summary + ul li a, li[role="list"] > ul li a {
  margin: calc(var(--form-element-spacing-vertical) * -.5) calc(var(--form-element-spacing-horizontal) * -1);
  padding: calc(var(--form-element-spacing-vertical) * .5) var(--form-element-spacing-horizontal);
  color: var(--dropdown-color);
  text-overflow: ellipsis;
  text-decoration: none;
  display: block;
  overflow: hidden;
}

details[role="list"] summary + ul li a:hover, li[role="list"] > ul li a:hover {
  background-color: var(--dropdown-hover-background-color);
}

details[role="list"] summary:after, li[role="list"] > a:after {
  height: calc(1rem * var(--line-height, 1.5));
  -webkit-margin-start: .5rem;
  float: right;
  content: "";
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 1rem;
  width: 1rem;
  margin-inline-start: .5rem;
  display: block;
  transform: rotate(0);
}

details[role="list"] {
  border-bottom: none;
  padding: 0;
}

details[role="list"] summary {
  margin-bottom: 0;
}

details[role="list"] summary:not([role]) {
  height: calc(1rem * var(--line-height)  + var(--form-element-spacing-vertical) * 2 + var(--border-width) * 2);
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  border: var(--border-width) solid var(--form-element-border-color);
  border-radius: var(--border-radius);
  background-color: var(--form-element-background-color);
  color: var(--form-element-placeholder-color);
  line-height: inherit;
  cursor: pointer;
  transition: background-color var(--transition), border-color var(--transition), color var(--transition), box-shadow var(--transition);
}

details[role="list"] summary:not([role]):active, details[role="list"] summary:not([role]):focus {
  border-color: var(--form-element-active-border-color);
  background-color: var(--form-element-active-background-color);
}

details[role="list"] summary:not([role]):focus {
  box-shadow: 0 0 0 var(--outline-width) var(--form-element-focus-color);
}

details[role="list"][open] summary {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

details[role="list"][open] summary:before {
  z-index: 1;
  content: "";
  cursor: default;
  background: none;
  display: block;
  position: fixed;
  inset: 0;
}

nav details[role="list"] summary, nav li[role="list"] a {
  direction: ltr;
  display: flex;
}

nav details[role="list"] summary + ul, nav li[role="list"] > ul {
  border-radius: var(--border-radius);
  min-width: -moz-fit-content;
  min-width: fit-content;
}

nav details[role="list"] summary + ul li a, nav li[role="list"] > ul li a {
  border-radius: 0;
}

nav details[role="list"] summary, nav details[role="list"] summary:not([role]) {
  padding: var(--nav-link-spacing-vertical) var(--nav-link-spacing-horizontal);
  height: auto;
}

nav details[role="list"][open] summary {
  border-radius: var(--border-radius);
}

nav details[role="list"] summary + ul {
  margin-top: var(--outline-width);
  -webkit-margin-start: 0;
  margin-inline-start: 0;
}

nav details[role="list"] summary[role="link"] {
  margin-bottom: calc(var(--nav-link-spacing-vertical) * -1);
  line-height: var(--line-height);
}

nav details[role="list"] summary[role="link"] + ul {
  margin-top: calc(var(--nav-link-spacing-vertical)  + var(--outline-width));
  -webkit-margin-start: calc(var(--nav-link-spacing-horizontal) * -1);
  margin-inline-start: calc(var(--nav-link-spacing-horizontal) * -1);
}

li[role="list"] a:active ~ ul, li[role="list"] a:focus ~ ul, li[role="list"]:hover > ul {
  display: flex;
}

li[role="list"] > ul {
  margin-top: calc(var(--nav-link-spacing-vertical)  + var(--outline-width));
  -webkit-margin-start: calc(var(--nav-element-spacing-horizontal)  - var(--nav-link-spacing-horizontal));
  margin-inline-start: calc(var(--nav-element-spacing-horizontal)  - var(--nav-link-spacing-horizontal));
  display: none;
}

li[role="list"] > a:after {
  background-image: var(--icon-chevron);
}

label > details[role="list"] {
  margin-top: calc(var(--spacing) * .25);
  margin-bottom: var(--spacing);
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-busy="true"]:not(input, select, textarea, html):before {
  content: "";
  vertical-align: text-bottom;
  vertical-align: -.125em;
  opacity: var(--loading-spinner-opacity);
  border: .1875em solid;
  border-right-color: #0000;
  border-radius: 1em;
  width: 1em;
  height: 1em;
  animation: .75s linear infinite spinner;
  display: inline-block;
}

[aria-busy="true"]:not(input, select, textarea, html):not(:empty):before {
  margin-right: calc(var(--spacing) * .5);
  -webkit-margin-start: 0;
  margin-left: 0;
  -webkit-margin-end: calc(var(--spacing) * .5);
  margin-inline-start: 0;
  margin-inline-end: calc(var(--spacing) * .5);
}

[aria-busy="true"]:not(input, select, textarea, html):empty {
  text-align: center;
}

a[aria-busy="true"], button[aria-busy="true"], input[type="button"][aria-busy="true"], input[type="reset"][aria-busy="true"], input[type="submit"][aria-busy="true"] {
  pointer-events: none;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

[data-tooltip] {
  position: relative;
}

[data-tooltip]:not(a, button, input) {
  cursor: help;
  border-bottom: 1px dotted;
  text-decoration: none;
}

[data-tooltip]:after, [data-tooltip]:before, [data-tooltip][data-placement="top"]:after, [data-tooltip][data-placement="top"]:before {
  z-index: 99;
  border-radius: var(--border-radius);
  background: var(--tooltip-background-color);
  content: attr(data-tooltip);
  color: var(--tooltip-color);
  font-style: normal;
  font-weight: var(--font-weight);
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  padding: .25rem .5rem;
  font-size: .875rem;
  text-decoration: none;
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -.25rem);
}

[data-tooltip]:after, [data-tooltip][data-placement="top"]:after {
  content: "";
  color: var(--tooltip-background-color);
  background-color: #0000;
  border-top: .3rem solid;
  border-left: .3rem solid #0000;
  border-right: .3rem solid #0000;
  border-radius: 0;
  padding: 0;
  transform: translate(-50%);
}

[data-tooltip][data-placement="bottom"]:after, [data-tooltip][data-placement="bottom"]:before {
  top: 100%;
  bottom: auto;
  transform: translate(-50%, .25rem);
}

[data-tooltip][data-placement="bottom"]:after {
  border: .3rem solid #0000;
  border-bottom-color: currentColor;
  transform: translate(-50%, -.3rem);
}

[data-tooltip][data-placement="left"]:after, [data-tooltip][data-placement="left"]:before {
  inset: 50% 100% auto auto;
  transform: translate(-.25rem, -50%);
}

[data-tooltip][data-placement="left"]:after {
  border: .3rem solid #0000;
  border-left-color: currentColor;
  transform: translate(.3rem, -50%);
}

[data-tooltip][data-placement="right"]:after, [data-tooltip][data-placement="right"]:before {
  inset: 50% auto auto 100%;
  transform: translate(.25rem, -50%);
}

[data-tooltip][data-placement="right"]:after {
  border: .3rem solid #0000;
  border-right-color: currentColor;
  transform: translate(-.3rem, -50%);
}

[data-tooltip]:focus:after, [data-tooltip]:focus:before, [data-tooltip]:hover:after, [data-tooltip]:hover:before {
  opacity: 1;
}

@media (hover: hover) and (pointer: fine) {
  [data-tooltip]:hover:after, [data-tooltip]:hover:before, [data-tooltip][data-placement="bottom"]:focus:after, [data-tooltip][data-placement="bottom"]:focus:before, [data-tooltip][data-placement="bottom"]:hover [data-tooltip]:focus:after, [data-tooltip][data-placement="bottom"]:hover [data-tooltip]:focus:before {
    animation-name: tooltip-slide-top;
    animation-duration: .2s;
  }

  [data-tooltip]:hover:after, [data-tooltip][data-placement="bottom"]:focus:after, [data-tooltip][data-placement="bottom"]:hover [data-tooltip]:focus:after {
    animation-name: tooltip-caret-slide-top;
  }

  [data-tooltip][data-placement="bottom"]:focus:after, [data-tooltip][data-placement="bottom"]:focus:before, [data-tooltip][data-placement="bottom"]:hover:after, [data-tooltip][data-placement="bottom"]:hover:before {
    animation-name: tooltip-slide-bottom;
    animation-duration: .2s;
  }

  [data-tooltip][data-placement="bottom"]:focus:after, [data-tooltip][data-placement="bottom"]:hover:after {
    animation-name: tooltip-caret-slide-bottom;
  }

  [data-tooltip][data-placement="left"]:focus:after, [data-tooltip][data-placement="left"]:focus:before, [data-tooltip][data-placement="left"]:hover:after, [data-tooltip][data-placement="left"]:hover:before {
    animation-name: tooltip-slide-left;
    animation-duration: .2s;
  }

  [data-tooltip][data-placement="left"]:focus:after, [data-tooltip][data-placement="left"]:hover:after {
    animation-name: tooltip-caret-slide-left;
  }

  [data-tooltip][data-placement="right"]:focus:after, [data-tooltip][data-placement="right"]:focus:before, [data-tooltip][data-placement="right"]:hover:after, [data-tooltip][data-placement="right"]:hover:before {
    animation-name: tooltip-slide-right;
    animation-duration: .2s;
  }

  [data-tooltip][data-placement="right"]:focus:after, [data-tooltip][data-placement="right"]:hover:after {
    animation-name: tooltip-caret-slide-right;
  }
}

@keyframes tooltip-slide-top {
  from {
    opacity: 0;
    transform: translate(-50%, .75rem);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -.25rem);
  }
}

@keyframes tooltip-caret-slide-top {
  from {
    opacity: 0;
  }

  50% {
    opacity: 0;
    transform: translate(-50%, -.25rem);
  }

  to {
    opacity: 1;
    transform: translate(-50%);
  }
}

@keyframes tooltip-slide-bottom {
  from {
    opacity: 0;
    transform: translate(-50%, -.75rem);
  }

  to {
    opacity: 1;
    transform: translate(-50%, .25rem);
  }
}

@keyframes tooltip-caret-slide-bottom {
  from {
    opacity: 0;
  }

  50% {
    opacity: 0;
    transform: translate(-50%, -.5rem);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -.3rem);
  }
}

@keyframes tooltip-slide-left {
  from {
    opacity: 0;
    transform: translate(.75rem, -50%);
  }

  to {
    opacity: 1;
    transform: translate(-.25rem, -50%);
  }
}

@keyframes tooltip-caret-slide-left {
  from {
    opacity: 0;
  }

  50% {
    opacity: 0;
    transform: translate(.05rem, -50%);
  }

  to {
    opacity: 1;
    transform: translate(.3rem, -50%);
  }
}

@keyframes tooltip-slide-right {
  from {
    opacity: 0;
    transform: translate(-.75rem, -50%);
  }

  to {
    opacity: 1;
    transform: translate(.25rem, -50%);
  }
}

@keyframes tooltip-caret-slide-right {
  from {
    opacity: 0;
  }

  50% {
    opacity: 0;
    transform: translate(-.05rem, -50%);
  }

  to {
    opacity: 1;
    transform: translate(-.3rem, -50%);
  }
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled="true"], [disabled] {
  cursor: not-allowed;
}

[aria-hidden="false"][hidden] {
  display: initial;
}

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

[tabindex], a, area, button, input, label, select, summary, textarea {
  -ms-touch-action: manipulation;
}

[dir="rtl"] {
  direction: rtl;
}

@media (prefers-reduced-motion: reduce) {
  :not([aria-busy="true"]), :not([aria-busy="true"]):after, :not([aria-busy="true"]):before {
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    animation-delay: -1ms !important;
  }
}

:root {
  --color-cat-1: #fd7f6f;
  --color-cat-1-mute: #fd7f6f80;
  --color-cat-2: #7eb0d5;
  --color-cat-2-mute: #7eb0d580;
  --color-cat-3: #b2e061;
  --color-cat-3-mute: #b2e06180;
  --color-cat-4: #bd7ebe;
  --color-cat-4-mute: #bd7ebe80;
  --color-cat-5: #ffb55a;
  --color-cat-5-mute: #ffb55a80;
  --color-cat-6: #ffee65;
  --color-cat-6-mute: #ffee6580;
  --color-cat-7: #beb9db;
  --color-cat-7-mute: #beb9db80;
  --color-cat-8: #fdcce5;
  --color-cat-8-mute: #fdcce580;
  --color-cat-9: #8bd3c7;
  --color-cat-9-mute: #8bd3c780;
  --color-red: #fd7f6f;
  --color-red-mute: #fd7f6f80;
  --color-blue: #7eb0d5;
  --color-blue-mute: #7eb0d580;
  --color-green: #b2e061;
  --color-green-mute: #b2e06180;
  --color-purple: #bd7ebe;
  --color-purple-mute: #bd7ebe80;
  --color-orange: #ffb55a;
  --color-orange-mute: #ffb55a80;
  --color-yellow: #ffee65;
  --color-yellow-mute: #ffee6580;
  --color-gray: #beb9db;
  --color-gray-mute: #beb9db80;
  --color-pink: #fdcce5;
  --color-pink-mute: #fdcce580;
  --color-cyan: #8bd3c7;
  --color-cyan-mute: #8bd3c780;
  --color-success: #b2e061;
  --color-success-mute: #b2e06180;
  --color-info: #7eb0d5;
  --color-info-mute: #7eb0d580;
  --color-error: #fd7f6f;
  --color-error-mute: #fd7f6f80;
}

.color-cat-1 {
  color: var(--color-cat-1);
}

.bgcolor-cat-1 {
  background-color: var(--color-cat-1);
}

.bgcolor-cat-1-mute {
  background-color: var(--color-cat-1-mute);
}

.color-cat-2 {
  color: var(--color-cat-2);
}

.bgcolor-cat-2 {
  background-color: var(--color-cat-2);
}

.bgcolor-cat-2-mute {
  background-color: var(--color-cat-2-mute);
}

.color-cat-3 {
  color: var(--color-cat-3);
}

.bgcolor-cat-3 {
  background-color: var(--color-cat-3);
}

.bgcolor-cat-3-mute {
  background-color: var(--color-cat-3-mute);
}

.color-cat-4 {
  color: var(--color-cat-4);
}

.bgcolor-cat-4 {
  background-color: var(--color-cat-4);
}

.bgcolor-cat-4-mute {
  background-color: var(--color-cat-4-mute);
}

.color-cat-5 {
  color: var(--color-cat-5);
}

.bgcolor-cat-5 {
  background-color: var(--color-cat-5);
}

.bgcolor-cat-5-mute {
  background-color: var(--color-cat-5-mute);
}

.color-cat-6 {
  color: var(--color-cat-6);
}

.bgcolor-cat-6 {
  background-color: var(--color-cat-6);
}

.bgcolor-cat-6-mute {
  background-color: var(--color-cat-6-mute);
}

.color-cat-7 {
  color: var(--color-cat-7);
}

.bgcolor-cat-7 {
  background-color: var(--color-cat-7);
}

.bgcolor-cat-7-mute {
  background-color: var(--color-cat-7-mute);
}

.color-cat-8 {
  color: var(--color-cat-8);
}

.bgcolor-cat-8 {
  background-color: var(--color-cat-8);
}

.bgcolor-cat-8-mute {
  background-color: var(--color-cat-8-mute);
}

.color-cat-9 {
  color: var(--color-cat-9);
}

.bgcolor-cat-9 {
  background-color: var(--color-cat-9);
}

.bgcolor-cat-9-mute {
  background-color: var(--color-cat-9-mute);
}

.color-red {
  color: var(--color-red);
}

.bgcolor-red {
  background-color: var(--color-red);
}

.bgcolor-red-mute {
  background-color: var(--color-red-mute);
}

.color-blue {
  color: var(--color-blue);
}

.bgcolor-blue {
  background-color: var(--color-blue);
}

.bgcolor-blue-mute {
  background-color: var(--color-blue-mute);
}

.color-green {
  color: var(--color-green);
}

.bgcolor-green {
  background-color: var(--color-green);
}

.bgcolor-green-mute {
  background-color: var(--color-green-mute);
}

.color-purple {
  color: var(--color-purple);
}

.bgcolor-purple {
  background-color: var(--color-purple);
}

.bgcolor-purple-mute {
  background-color: var(--color-purple-mute);
}

.color-orange {
  color: var(--color-orange);
}

.bgcolor-orange {
  background-color: var(--color-orange);
}

.bgcolor-orange-mute {
  background-color: var(--color-orange-mute);
}

.color-yellow {
  color: var(--color-yellow);
}

.bgcolor-yellow {
  background-color: var(--color-yellow);
}

.bgcolor-yellow-mute {
  background-color: var(--color-yellow-mute);
}

.color-gray {
  color: var(--color-gray);
}

.bgcolor-gray {
  background-color: var(--color-gray);
}

.bgcolor-gray-mute {
  background-color: var(--color-gray-mute);
}

.color-pink {
  color: var(--color-pink);
}

.bgcolor-pink {
  background-color: var(--color-pink);
}

.bgcolor-pink-mute {
  background-color: var(--color-pink-mute);
}

.color-cyan {
  color: var(--color-cyan);
}

.bgcolor-cyan {
  background-color: var(--color-cyan);
}

.bgcolor-cyan-mute {
  background-color: var(--color-cyan-mute);
}

.color-success {
  color: var(--color-success);
}

.bgcolor-success {
  background-color: var(--color-success);
}

.bgcolor-success-mute {
  background-color: var(--color-success-mute);
}

.color-info {
  color: var(--color-info);
}

.bgcolor-info {
  background-color: var(--color-info);
}

.bgcolor-info-mute {
  background-color: var(--color-info-mute);
}

.color-error {
  color: var(--color-error);
}

.bgcolor-error {
  background-color: var(--color-error);
}

.bgcolor-error-mute {
  background-color: var(--color-error-mute);
}

.ui_table_wrapper {
  border: var(--border-width) solid var(--table-border-color);
  border-radius: var(--border-radius);
  flex-direction: column;
  margin-block-end: var(--typography-spacing-vertical);
  display: flex;
}

.ui_table_wrapper figure {
  flex-direction: column;
  flex-grow: 1;
  margin-block-end: 0;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.ui_table_wrapper :is(header, footer) {
  justify-content: space-between;
  align-items: flex-start;
  gap: calc(var(--spacing) / 2);
  padding: calc(var(--spacing) / 2);
  background-color: var(--well-background-color);
  flex-direction: column;
  flex: none;
  display: flex;
}

.ui_table_wrapper header {
  border-block-end: var(--border-width) solid var(--table-border-color);
}

.ui_table_wrapper header label.lbl {
  max-inline-size: unset;
  flex: none;
  inline-size: 100%;
  margin-block-end: 2px;
}

.ui_table_wrapper footer {
  border-block-start: var(--border-width) solid var(--table-border-color);
}

.ui_table_wrapper .ctrl_group {
  gap: calc(var(--spacing) / 2);
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  block-size: 100%;
  inline-size: 100%;
  font-size: .8em;
  display: flex;
}

.ui_table_wrapper .ctrl_group input[type="text"] {
  --line-height: 1rem;
  border-color: var(--table-border-color);
  background-color: var(--card-background-color);
  inline-size: 20ch;
  margin-block-end: 0;
  font-size: small;
}

.ui_table_wrapper .ctrl_group .busy {
  inline-size: 1em;
  font-size: .8em;
}

.ui_table_wrapper .ctrl_group mark {
  color: var(--code-color);
  background-color: var(--code-background-color);
  border-radius: var(--border-radius);
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  flex: none;
  line-height: 1em;
}

.ui_table_wrapper .ctrl_group button {
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  border: 0;
  margin-block-end: 0;
  font-size: 1em;
  line-height: 1em;
}

@media (min-width: 768px) {
  .ui_table_wrapper :is(header, footer) {
    flex-direction: row;
    align-items: center;
    gap: 0;
  }

  .ui_table_wrapper header label.lbl {
    flex: 5 auto;
    inline-size: auto;
  }

  .ui_table_wrapper .ctrl_group {
    flex: 1 0 auto;
    inline-size: auto;
  }
}

.ui_table_wrapper.s {
  block-size: var(--small-height);
  flex-grow: 1;
}

.ui_table_wrapper.m {
  block-size: var(--medium-height);
  flex-grow: 1;
}

.ui_table_wrapper.l {
  block-size: var(--large-height);
  flex-grow: 1;
}

.ui_table_wrapper.stretch {
  min-block-size: var(--stretch-min-height);
  flex-grow: 100;
  block-size: 100%;
  max-block-size: 90vh;
}

.ui_table_wrapper figure > div {
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

table.ui_table {
  border-collapse: separate;
  inline-size: auto;
  min-inline-size: 100%;
  margin-block-end: 0;
}

table.ui_table.fixed {
  table-layout: fixed;
  inline-size: 100%;
}

table.ui_table th {
  background-color: var(--well-background-color);
  --border-width: 1px;
  white-space: nowrap;
  min-inline-size: 30px;
  font-size: small;
  font-weight: 700;
  position: relative;
}

table.ui_table thead.sticky {
  position: sticky;
  inset-block-start: 0;
}

table.ui_table thead th.empty {
  border: 0;
  padding: 0;
}

table.ui_table th, table.ui_table .col_sort_asc, table.ui_table .col_sort_desc {
  text-overflow: ellipsis;
  overflow: hidden;
}

table.ui_table .col_sort_asc {
  cursor: s-resize;
}

table.ui_table .col_sort_desc {
  cursor: n-resize;
}

table.ui_table td {
  white-space: nowrap;
  text-overflow: ellipsis;
  border-block-end: none;
  min-inline-size: 3ch;
  max-inline-size: 30ch;
  overflow: hidden;
}

table.ui_table :not(thead, tfoot) > * > td {
  --font-size: 1em;
}

table.ui_table :is(td, th) {
  padding-inline-end: 0;
}

table.ui_table :is(td, th):last-child {
  padding-inline-end: var(--spacing);
}

table.ui_table td progress {
  inline-size: 5em;
  margin: 0;
}

table.ui_table td input[type="checkbox"] {
  margin: 0;
}

table.ui_table td .stacked_bar {
  min-inline-size: 10em;
  display: flex;
}

table.ui_table th.image, table.ui_table th.avatar, table.ui_table th.narrow, table.ui_table td.narrow {
  inline-size: 3%;
}

table.ui_table td svg.feather {
  block-size: 1rem;
  inline-size: 1rem;
  display: block;
}

@media (min-width: 768px) {
  table.ui_table td progress {
    inline-size: 10em;
  }
}

table.ui_table td .img_wrapper {
  border-radius: var(--border-radius);
  border: 1px solid var(--table-border-color);
  background-position: center;
  background-size: cover;
  block-size: 3em;
  inline-size: 4em;
}

table.ui_table td .avatar {
  block-size: 2em;
  inline-size: 2em;
  font-size: 1.5em;
}

table.ui_table td .avatar:before {
  content: attr(data-initials);
  aspect-ratio: 1;
  z-index: -1;
  border-radius: 50%;
  place-content: center;
  block-size: 100%;
  inline-size: 100%;
  display: grid;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

.col_adj_overlay {
  cursor: ew-resize;
  block-size: 100vh;
  inline-size: 100vw;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
}

.col_adj_container {
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.col_adj {
  cursor: ew-resize;
  border-inline-start: 1px solid #0000;
  block-size: 1rem;
  inline-size: 1px;
  margin-block-start: 2px;
  position: absolute;
  inset-inline-end: 4px;
}

.col_adj:before {
  content: " ";
  opacity: 0;
  cursor: ew-resize;
  inline-size: 8px;
  display: block;
  position: absolute;
  transform: translate(-4px, -2px);
}

.col_adj_active {
  border-color: var(--primary);
}

table.ui_table thead:hover .col_adj_hover {
  border-color: var(--muted-color);
  opacity: .2;
}

table.ui_table th:hover .col_adj_hover {
  border-color: var(--muted-color);
  opacity: 1;
}

table.ui_table th:hover .col_adj_hover:hover {
  border-color: var(--primary);
}

table.ui_table td[data-fmt="multiline"] {
  text-wrap: initial;
}

table.ui_table td[data-fmt="multiline"] > span {
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  -webkit-box-orient: vertical;
  min-inline-size: 7em;
  max-inline-size: 80ch;
  margin-inline-end: 1em;
  display: -webkit-box;
  overflow: hidden;
}

table.ui_table td[data-fmt="paragraph"] {
  text-wrap: initial;
}

table.ui_table td[data-fmt="paragraph"] > span {
  min-inline-size: 7em;
  max-inline-size: 80ch;
  margin-inline-end: 1em;
}

tr.even {
  background-color: var(--table-row-stripped-background-color);
}

.actions {
  gap: var(--spacing);
  display: flex;
}

.actions span[role="link"] {
  white-space: nowrap;
}

@media (hover: hover) {
  .hover_action span[role="link"] {
    visibility: hidden;
  }

  .is_adjusting_col_widths .hover_action span[role="link"], tr:hover .hover_action span[role="link"] {
    visibility: visible;
  }
}

table.ui_table td:last-child .actions_end {
  text-align: end;
  justify-content: end;
}

.action_menu_button {
  --padding: calc(var(--spacing) / 2);
  --negative-padding: calc(-1 * var(--padding));
  padding: var(--padding);
  margin: var(--negative-padding);
  border-radius: var(--border-radius);
  display: inline-block;
}

.action_menu_button[data-open] {
  background-color: var(--primary-focus);
}

table.ui_table td svg {
  vertical-align: text-top;
  block-size: 1rem;
  inline-size: 1rem;
  display: inline-block;
}

table.ui_table td .with_icon svg {
  margin-block-start: 1px;
}

.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}

button.rbc-btn {
  text-transform: none;
  -webkit-appearance: button;
  appearance: button;
  cursor: pointer;
  overflow: visible;
}

button[disabled].rbc-btn {
  cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.rbc-calendar {
  box-sizing: border-box;
  flex-direction: column;
  align-items: stretch;
  block-size: 100%;
  display: flex;
}

.rbc-calendar *, .rbc-calendar :before, .rbc-calendar :after {
  box-sizing: inherit;
}

.rbc-abs-full, .rbc-row-bg {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: var(--muted-color);
}

.rbc-off-range-bg {
  background: var(--well-border-color);
}

.rbc-header {
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  border-block-end: 1px solid var(--table-border-color);
  flex: 1 0;
  min-block-size: 0;
  padding: 0 3px;
  font-size: 90%;
  font-weight: bold;
  overflow: hidden;
}

.rbc-header + .rbc-header {
  border-inline-start: 1px solid var(--table-border-color);
}

.rbc-rtl .rbc-header + .rbc-header {
  border-inline-start-width: 0;
  border-inline-end: 1px solid var(--table-border-color);
}

.rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-button-link {
  color: inherit;
  cursor: pointer;
  -webkit-user-select: text;
  user-select: text;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.rbc-row-content {
  -webkit-user-select: none;
  user-select: none;
  z-index: 4;
  position: relative;
}

.rbc-row-content-scrollable {
  flex-direction: column;
  block-size: 100%;
  display: flex;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
  block-size: 100%;
  overflow-y: scroll;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
  display: none;
}

.rbc-today {
  background-color: var(--primary-focus);
}

.rbc-toolbar {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-block-end: 10px;
  font-size: 16px;
  display: flex;
}

.rbc-toolbar .rbc-toolbar-label {
  text-align: center;
  flex-grow: 1;
  padding: 0 10px;
}

.rbc-toolbar button {
  color: var(--code-color);
  text-align: center;
  vertical-align: middle;
  border: 1px solid var(--dropdown-border-color);
  white-space: nowrap;
  background: none;
  border-radius: 4px;
  margin: 0;
  padding: .375rem 1rem;
  line-height: normal;
  display: inline-block;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  background-image: none;
  background-color: var(--well-background-color);
  border-color: var(--dropdown-border-color);
  box-shadow: inset 0 3px 5px #00000020;
}

.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
  color: var(--code-color);
  background-color: var(--primary-focus);
  border-color: var(--dropdown-border-color);
}

.rbc-toolbar button:focus, .rbc-toolbar button:hover {
  color: var(--code-color);
  background-color: var(--well-background-color);
  border-color: var(--dropdown-border-color);
}

.rbc-btn-group {
  white-space: nowrap;
  display: inline-block;
}

.rbc-btn-group > button:first-child:not(:last-child) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.rbc-btn-group > button:last-child:not(:first-child) {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.rbc-btn-group > button:not(:first-child, :last-child) {
  border-radius: 0;
}

.rbc-btn-group button + button {
  margin-inline-start: -1px;
}

.rbc-rtl .rbc-btn-group button + button {
  margin-inline: 0 -1px;
}

.rbc-btn-group + .rbc-btn-group, .rbc-btn-group + button {
  margin-inline-start: 10px;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  box-sizing: border-box;
  box-shadow: none;
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  background-color: var(--primary);
  border-radius: var(--border-radius);
  color: var(--color-cat-2-mute);
  cursor: pointer;
  text-align: start;
  border: none;
  inline-size: 100%;
  margin: 0;
}

.rbc-slot-selecting .rbc-event, .rbc-slot-selecting .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-slot-selecting .rbc-background-event {
  cursor: inherit;
  pointer-events: none;
}

.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: var(--well-background-color);
}

.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
  outline: 5px auto var(--primary-focus);
}

.rbc-event-label {
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px #33333380;
}

.rbc-event-continues-prior {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.rbc-event-continues-after {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.rbc-event-continues-earlier {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.rbc-event-continues-later {
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.rbc-row {
  flex-direction: row;
  display: flex;
}

.rbc-row-segment {
  padding: 0 1px 1px;
}

.rbc-selected-cell {
  background-color: var(--primary-focus);
}

.rbc-show-more {
  z-index: 4;
  color: var(--primary);
  background-color: #ffffff4d;
  block-size: auto;
  font-size: 85%;
  font-weight: bold;
  line-height: normal;
}

.rbc-show-more:hover, .rbc-show-more:focus {
  color: var(--well-background-color);
}

.rbc-month-view {
  border: 1px solid var(--table-border-color);
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  flex: 1 0 0;
  block-size: 100%;
  inline-size: 100%;
  display: flex;
  position: relative;
}

.rbc-month-header {
  flex-direction: row;
  display: flex;
}

.rbc-month-row {
  flex-direction: column;
  flex: 1 0 0;
  block-size: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.rbc-month-row + .rbc-month-row {
  border-block-start: 1px solid var(--table-border-color);
}

.rbc-date-cell {
  text-align: end;
  flex: 1 1 0;
  min-inline-size: 0;
  padding-inline-end: 5px;
}

.rbc-date-cell.rbc-now {
  font-weight: bold;
}

.rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-bg {
  flex-direction: row;
  flex: 1 0 0;
  display: flex;
  overflow: hidden;
}

.rbc-day-bg {
  flex: 1 0;
}

.rbc-day-bg + .rbc-day-bg {
  border-inline-start: 1px solid var(--table-border-color);
}

.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-inline-start-width: 0;
  border-inline-end: 1px solid var(--table-border-color);
}

.rbc-overlay {
  z-index: 5;
  border: 1px solid var(--table-border-color);
  background-color: var(--background-color);
  padding: 10px;
  position: absolute;
  box-shadow: 0 5px 15px #00000040;
}

.rbc-overlay > * + * {
  margin-block-start: 1px;
}

.rbc-overlay-header {
  border-block-end: 1px solid var(--table-border-color);
  margin: -10px -10px 5px;
  padding: 2px 10px;
}

.rbc-agenda-view {
  flex-direction: column;
  flex: 1 0 0;
  display: flex;
  overflow: auto;
}

.rbc-agenda-view table.rbc-agenda-table {
  border: 1px solid var(--table-border-color);
  border-spacing: 0;
  border-collapse: collapse;
  inline-size: 100%;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  vertical-align: top;
  padding: 5px 10px;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  text-transform: lowercase;
  padding-inline: 15px;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-inline-start: 1px solid var(--table-border-color);
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-inline-start-width: 0;
  border-inline-end: 1px solid var(--table-border-color);
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-block-start: 1px solid var(--table-border-color);
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: start;
  border-block-end: 1px solid var(--table-border-color);
  padding: 3px 5px;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: end;
}

.rbc-agenda-time-cell {
  text-transform: lowercase;
}

.rbc-agenda-time-cell .rbc-continues-after:after {
  content: " »";
}

.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: "« ";
}

.rbc-agenda-date-cell, .rbc-agenda-time-cell {
  white-space: nowrap;
}

.rbc-agenda-event-cell {
  inline-size: 100%;
}

.rbc-time-column {
  flex-direction: column;
  min-block-size: 100%;
  display: flex;
}

.rbc-time-column .rbc-timeslot-group {
  flex: 1;
}

.rbc-timeslot-group {
  border-block-end: 1px solid var(--table-border-color);
  flex-flow: column;
  min-block-size: 40px;
  display: flex;
}

.rbc-time-gutter, .rbc-header-gutter {
  flex: none;
}

.rbc-label {
  padding: 0 5px;
}

.rbc-day-slot {
  position: relative;
}

.rbc-day-slot .rbc-events-container {
  margin-inline-end: 10px;
  position: absolute;
  inset: 0;
}

.rbc-day-slot .rbc-events-container.rbc-rtl {
  inset-inline: 10px 0;
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
  border: 1px solid none;
  flex-flow: column wrap;
  align-items: flex-start;
  min-block-size: 20px;
  max-block-size: 100%;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.rbc-day-slot .rbc-background-event {
  opacity: .75;
}

.rbc-day-slot .rbc-event-label {
  flex: none;
  inline-size: auto;
  padding-inline-end: 5px;
}

.rbc-day-slot .rbc-event-content {
  word-wrap: break-word;
  flex: 1 1 0;
  block-size: 100%;
  min-block-size: 1em;
  inline-size: 100%;
  line-height: 1;
}

.rbc-day-slot .rbc-time-slot {
  border-block-start: 1px solid var(--time-slot-border-separator-color);
}

.rbc-time-view-resources .rbc-time-gutter, .rbc-time-view-resources .rbc-time-header-gutter {
  border-inline-end: 1px solid var(--table-border-color);
  z-index: 10;
  background-color: #fff;
  margin-inline-end: -1px;
  position: sticky;
  inset-inline-start: 0;
}

.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}

.rbc-time-view-resources .rbc-time-header-content {
  flex: 1 0 0;
  min-inline-size: auto;
}

.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-view-resources .rbc-day-slot {
  min-inline-size: 140px;
}

.rbc-time-view-resources .rbc-header, .rbc-time-view-resources .rbc-day-bg {
  flex: 1 1 0;
  flex-basis: 0 px;
  inline-size: 140px;
}

.rbc-time-header-content + .rbc-time-header-content {
  margin-inline-start: -1px;
}

.rbc-time-slot {
  flex: 1 0 0;
}

.rbc-time-slot.rbc-now {
  font-weight: bold;
}

.rbc-day-header {
  text-align: center;
}

.rbc-slot-selection {
  z-index: 10;
  background-color: var(--primary-focus);
  color: var(--primary);
  inline-size: 100%;
  padding: 3px;
  font-size: 75%;
  position: absolute;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  border: 1px solid var(--table-border-color);
  flex-direction: column;
  flex: 1;
  min-block-size: 0;
  inline-size: 100%;
  display: flex;
}

.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
  text-align: end;
}

.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  block-size: 100%;
  inline-size: 100%;
  position: relative;
}

.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-inline-start: 1px solid var(--table-border-color);
}

.rbc-time-view .rbc-allday-events {
  z-index: 4;
  position: relative;
}

.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-block-size: 20px;
}

.rbc-time-header {
  flex-direction: row;
  flex: none;
  display: flex;
}

.rbc-time-header.rbc-overflowing {
  border-inline-end: 1px solid var(--table-border-color);
}

.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-inline-end-width: 0;
  border-inline-start: 1px solid var(--table-border-color);
}

.rbc-time-header > .rbc-row:first-child, .rbc-time-header > .rbc-row.rbc-row-resource {
  border-block-end: 1px solid var(--table-border-color);
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  border-inline-start: 1px solid var(--table-border-color);
  flex-direction: column;
  flex: 1;
  min-inline-size: 0;
  display: flex;
}

.rbc-rtl .rbc-time-header-content {
  border-inline-start-width: 0;
  border-inline-end: 1px solid var(--table-border-color);
}

.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-block-end: 1px solid var(--table-border-color);
  flex-shrink: 0;
}

.rbc-time-content {
  border-block-start: 2px solid var(--table-border-color);
  flex: 1 0;
  align-items: flex-start;
  inline-size: 100%;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.rbc-time-content > .rbc-time-gutter {
  flex: none;
}

.rbc-time-content > * + * > * {
  border-inline-start: 1px solid var(--table-border-color);
}

.rbc-rtl .rbc-time-content > * + * > * {
  border-inline-start-width: 0;
  border-inline-end: 1px solid var(--table-border-color);
}

.rbc-time-content > .rbc-day-slot {
  -webkit-user-select: none;
  user-select: none;
  inline-size: 100%;
}

.rbc-current-time-indicator {
  z-index: 3;
  background-color: var(--primary);
  pointer-events: none;
  block-size: 1px;
  position: absolute;
  inset-inline: 0;
}

.rbc-addons-dnd .rbc-addons-dnd-row-body {
  position: relative;
}

.rbc-addons-dnd .rbc-addons-dnd-drag-row {
  position: absolute;
  inset-block-start: 0;
  inset-inline: 0;
}

.rbc-addons-dnd .rbc-addons-dnd-over {
  background-color: var(--calendar-dnd-hover);
}

.rbc-addons-dnd .rbc-event, .rbc-addons-dnd .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-addons-dnd .rbc-background-event {
  transition: opacity .15s;
}

.rbc-addons-dnd .rbc-event:hover .rbc-addons-dnd-resize-ns-icon, .rbc-addons-dnd .rbc-day-slot .rbc-background-event:hover .rbc-addons-dnd-resize-ns-icon, .rbc-day-slot .rbc-addons-dnd .rbc-background-event:hover .rbc-addons-dnd-resize-ns-icon, .rbc-addons-dnd .rbc-event:hover .rbc-addons-dnd-resize-ew-icon, .rbc-addons-dnd .rbc-day-slot .rbc-background-event:hover .rbc-addons-dnd-resize-ew-icon, .rbc-day-slot .rbc-addons-dnd .rbc-background-event:hover .rbc-addons-dnd-resize-ew-icon {
  display: block;
}

.rbc-addons-dnd .rbc-addons-dnd-dragged-event {
  opacity: 0;
}

.rbc-addons-dnd.rbc-addons-dnd-is-dragging .rbc-event:not(.rbc-addons-dnd-dragged-event, .rbc-addons-dnd-drag-preview), .rbc-addons-dnd.rbc-addons-dnd-is-dragging .rbc-day-slot .rbc-background-event:not(.rbc-addons-dnd-dragged-event, .rbc-addons-dnd-drag-preview), .rbc-day-slot .rbc-addons-dnd.rbc-addons-dnd-is-dragging .rbc-background-event:not(.rbc-addons-dnd-dragged-event, .rbc-addons-dnd-drag-preview) {
  opacity: .5;
}

.rbc-addons-dnd .rbc-addons-dnd-resizable {
  block-size: 100%;
  inline-size: 100%;
  position: relative;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor {
  text-align: center;
  inline-size: 100%;
  position: absolute;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:first-child {
  inset-block-start: 0;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:last-child {
  inset-block-end: 0;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor .rbc-addons-dnd-resize-ns-icon {
  cursor: ns-resize;
  border-block-start: 3px double;
  inline-size: 10px;
  margin: 0 auto;
  display: none;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor {
  position: absolute;
  inset-block: 4px 0;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor:first-child {
  inset-inline-start: 0;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor:last-child {
  inset-inline-end: 0;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor .rbc-addons-dnd-resize-ew-icon {
  cursor: ew-resize;
  border-inline-start: 3px double;
  block-size: 10px;
  margin-block: auto;
  display: none;
}

.ui_calendar_wrapper.s {
  block-size: var(--small-height);
}

.ui_calendar_wrapper.m {
  block-size: var(--medium-height);
}

.ui_calendar_wrapper.l {
  block-size: var(--large-height);
}

.ui_calendar_wrapper.stretch {
  min-block-size: var(--stretch-min-height);
  flex-grow: 100;
  block-size: 90vh;
  max-block-size: 90vh;
}

.rbc-calendar {
  background-color: var(--well-background-color);
  padding: calc(var(--spacing) / 2);
  border-radius: var(--border-radius);
  border: var(--border-width) solid var(--table-border-color);
}

.rbc-toolbar {
  font-weight: var(--form-label-font-weight);
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  justify-content: space-between;
  margin-block-end: calc(var(--spacing) / 2);
  display: grid;
}

.rbc-toolbar .pagination {
  gap: var(--spacing);
  margin-inline-start: auto;
}

.rbc-toolbar .pagination .busy {
  inline-size: 1em;
}

.rbc-toolbar .lbl.title {
  text-align: center;
}

.rbc-btn-group {
  color: var(--muted-color);
  align-items: center;
  display: flex;
}

.rbc-toolbar button {
  border-width: var(--border-width);
  color: var(--code-color);
  background-color: var(--code-background-color);
  border-radius: var(--border-radius);
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  min-inline-size: unset;
  font-size: .85rem;
}

.rbc-toolbar button:focus, .rbc-toolbar button:hover {
  color: var(--form-element-color);
  background-color: var(--well-background-color);
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  background-image: none;
  background-color: var(--primary-focus);
  border-color: none;
  box-shadow: none;
  color: var(--form-element-color);
}

.rbc-button-link {
  font-size: .85rem;
}

.rbc-time-content {
  border-block-start: 1px solid var(--table-border-color);
  font-size: .85rem;
}

.rbc-time-content > .rbc-time-gutter {
  flex: none;
}

.rbc-current-time-indicator {
  background-color: var(--primary);
  block-size: var(--outline-width);
}

.rbc-day-slot .rbc-event {
  border: none;
  flex-flow: column-reverse;
  justify-content: flex-end;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  background-color: var(--color-cat-2-mute);
  color: var(--contrast);
  font-size: .85rem;
}

.rbc-event:hover {
  background-color: var(--color-cat-2);
}

.rbc-day-slot .rbc-time-slot {
  border-block-start: none;
}

.rbc-off-range-bg {
  background-color: var(--well-border-color);
}

.rbc-show-more {
  color: unset;
  font-size: .85rem;
  font-weight: normal;
}

.rbc-show-more:hover, .rbc-show-more:focus {
  color: var(--primary-hover);
}

.rbc-calendar-title {
  margin-inline-end: var(--spacing);
}

.popup_menu {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  display: flex;
  position: absolute;
  border-radius: var(--border-radius) !important;
}

.popup_menu:focus-visible {
  outline: none;
}

.popup_menu :focus-visible {
  background-color: var(--primary-focus);
  outline: none;
}

.popup_menu li[role="separator"] {
  pointer-events: none;
  margin: 0;
}

.popup_menu li[role="separator"] hr {
  margin: 0;
}

.popup_menu li {
  align-items: center;
  display: flex;
}

.popup_menu li.menu_heading {
  pointer-events: none;
  font-weight: bold;
}

.menu_parent:after {
  block-size: calc(1rem * var(--line-height, 1.5));
  content: "";
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 1rem;
  background-image: var(--icon-chevron);
  transition: transform var(--transition);
  flex-shrink: 0;
  inline-size: 1rem;
  display: block;
}

.menu_parent[data-open="true"] {
  background-color: var(--primary-focus);
}

:root {
  --sidebar-width: clamp(200px, 17vw, 300px);
  --menubar-image-height: 24px;
  --menubar-height: 0px;
  --spacing: .5rem;
  --form-element-spacing-vertical: .25rem;
  --form-element-spacing-horizontal: .5em;
  --typography-spacing-vertical: 1rem;
  --well-background-color: var(--table-row-stripped-background-color);
  --well-border-color: var(--table-border-color);
  --small-height: max(300px, 25vh);
  --medium-height: max(350px, 40vh);
  --large-height: max(400px, 80vh);
  --stretch-min-height: max(300px, 25vh);
  --font-size: 16px !important;
}

.has_menubar {
  --menubar-height: calc(var(--border-width)  + var(--menubar-image-height)  + 2 * var(--typography-spacing-vertical));
}

.dark_mode {
  display: none !important;
}

.invert {
  filter: invert();
}

[data-theme="light"] {
  --well-background-color: var(--table-row-stripped-background-color);
}

[data-theme="light"] .light_mode {
  display: block !important;
}

[data-theme="light"] .dark_mode {
  display: none !important;
}

@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme]) {
    --well-background-color: #18232c;
  }

  .light_mode {
    display: none !important;
  }

  .dark_mode {
    display: block !important;
  }
}

[data-theme="dark"] {
  --well-background-color: #18232c;
}

[data-theme="dark"] .light_mode {
  display: none !important;
}

[data-theme="dark"] .dark_mode {
  display: block !important;
}

.display_none {
  display: none !important;
}

@media (max-width: 768px) {
  .desktop {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .mobile {
    display: none !important;
  }
}

html, body, #root, main {
  flex-direction: column;
  min-block-size: 100%;
  display: flex;
}

:is(html, body, #root, main, .in_row, .region_wrapper):has(.stretch) {
  flex-grow: 1;
}

form {
  margin-block-end: 0;
}

main#homepage {
  min-block-size: unset;
}

.region_wrapper {
  flex-direction: column;
  display: flex;
}

.region_wrapper.stretch {
  min-block-size: var(--stretch-min-height);
  flex-grow: 100;
  block-size: 100%;
  max-block-size: 90vh;
}

article.region_wrapper {
  padding: var(--spacing);
  block-size: 100%;
  margin: 0;
  margin-block-end: var(--spacing);
  padding-block-end: 0;
}

article {
  margin: 0 0 var(--typography-spacing-vertical) 0;
}

.container-well {
  margin: 0 0 var(--typography-spacing-vertical) 0;
  padding: var(--block-spacing-vertical) var(--block-spacing-horizontal);
  background-color: var(--well-background-color);
  border: 1px solid var(--table-border-color);
  border-radius: var(--border-radius);
}

.container-well input, .container-well textarea, .container-well select {
  background-color: var(--code-background-color);
}

.applet {
  --form-label-font-weight: 500;
  padding: calc(2 * var(--spacing));
}

label.lbl {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-inline-size: 50vw;
  font-size: .9em;
  overflow: hidden;
}

@media (min-width: 1200px) {
  .applet {
    max-inline-size: unset;
    inline-size: 100%;
  }
}

ul {
  margin-inline-start: .5em;
}

h1, h2, h3, h4, h5, h6 {
  margin-block: var(--typography-spacing-vertical) 1rem;
  line-height: normal;
}

:is(h1, h2, h3, h4, h5, h6):first-child {
  margin-block-start: 0;
}

:is(form, p, h1, h2, h3, h4, h5, h6):last-child {
  margin-block-end: 0;
}

h1 {
  --font-size: 1.6rem;
  --typography-spacing-vertical: 1.5rem;
}

h2 {
  --font-size: 1.4rem;
  --typography-spacing-vertical: 1.3rem;
}

h3 {
  --font-size: 1.3rem;
  --typography-spacing-vertical: 1.2rem;
}

h4 {
  --font-size: 1.2rem;
  --typography-spacing-vertical: 1.1rem;
}

h5 {
  --font-size: 1.1rem;
  --typography-spacing-vertical: 1rem;
}

h6 {
  --font-size: 1rem;
  --typography-spacing-vertical: 1rem;
  font-weight: 600;
}

label {
  margin-block-end: 0;
}

svg.disabled {
  opacity: var(--form-element-disabled-opacity);
}

.flex {
  gap: var(--grid-spacing-horizontal);
  flex-direction: row;
  inline-size: 100%;
  margin-inline-end: auto;
  display: flex;
}

.flex > .region_wrapper {
  flex: 1;
}

.flex .row_gap {
  inline-size: var(--font-size);
}

.flex .vertical_sep {
  border-inline-end: 1px solid var(--muted-border-color);
  align-self: stretch;
  inline-size: 0;
}

[role="link"], .clickable {
  cursor: pointer;
}

svg.clickable:not(.disabled):hover {
  color: var(--primary);
}

button, a.link {
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  inline-size: 100%;
  min-inline-size: 4em;
  margin-inline-end: 0;
  display: flex;
  overflow: hidden;
  cursor: pointer !important;
  pointer-events: unset !important;
}

button {
  justify-content: center;
}

button[disabled] {
  cursor: not-allowed !important;
}

.button_like {
  cursor: pointer !important;
}

.button_like[aria-disabled] {
  opacity: .75;
  cursor: not-allowed !important;
}

.button_like .pill {
  min-inline-size: 3ch;
}

.in_row .button_aligned {
  block-size: calc(var(--font-size) * var(--line-height)  + var(--form-element-spacing-vertical) * 2 + var(--border-width) * 2);
  align-items: center;
  display: inline-flex;
}

@media (min-width: 768px) {
  .in_row span.button_like {
    padding: calc(var(--form-element-spacing-vertical)  + 2 * var(--border-width)) 0;
  }
}

.zeroWidthSpace {
  --form-label-font-weight: 500;
  display: none;
}

.pills {
  align-items: center;
  gap: calc(var(--spacing) / 2);
  flex-wrap: wrap;
  display: flex;
}

.pills code, .pill {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--contrast);
  align-items: center;
  padding: 4px 6px;
  display: inline-flex;
  overflow: hidden;
}

.pagination {
  color: var(--muted-color);
  align-items: center;
  display: flex;
}

@media (min-width: 768px) {
  .pills code, .pill {
    max-inline-size: 20vw;
  }
}

.nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

input[type="range"] {
  block-size: auto !important;
  margin-block-end: calc(2 * var(--spacing)) !important;
}

.menu li {
  min-inline-size: 7em;
}

.menu li:hover {
  background-color: var(--primary-focus);
}

.combobox details[role="list"] summary:not([role]):focus-within {
  box-shadow: 0 0 0 var(--outline-width) var(--form-element-focus-color);
}

.combobox details[role="list"] summary {
  justify-content: stretch;
  align-items: center;
  gap: calc(var(--spacing) / 2);
  display: flex;
  block-size: auto !important;
}

.combobox details[role="list"][open] summary:before {
  pointer-events: none;
}

.combobox details[role="list"] summary input[type="text"] {
  flex: 1;
  box-shadow: none !important;
  border: 0 !important;
  block-size: auto !important;
  margin: 0 !important;
  padding: 0 !important;
}

.combobox details[role="list"] summary .pills {
  flex-grow: 10;
}

.combobox details[role="list"] summary .pills code {
  gap: calc(var(--spacing) / 2);
  line-height: var(--font-size);
}

.combobox .pills code.value_selected {
  box-shadow: 0 0 0 var(--outline-width) var(--form-element-focus-color);
}

.combobox details[role="list"] ul[role="list"] {
  max-block-size: max(350px, 40vh);
  overflow-y: auto;
}

details[role="list"] summary[aria-invalid] {
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 1rem;
  padding-inline: var(--form-element-spacing-horizontal) calc(var(--form-element-spacing-horizontal)  + 1.5rem) !important;
}

details[role="list"] summary[aria-invalid="false"] {
  background-image: var(--icon-valid);
  --form-element-border-color: var(--form-element-valid-border-color);
}

details[role="list"] summary[aria-invalid="true"] {
  background-image: var(--icon-invalid);
  --form-element-border-color: var(--form-element-invalid-border-color);
}

details[role="list"] summary[aria-invalid="false"]:is(:active, :focus) {
  --form-element-border-color: var(--form-element-valid-active-border-color) !important;
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-valid-focus-color) !important;
}

details[role="list"] summary[aria-invalid="true"]:is(:active, :focus) {
  --form-element-border-color: var(--form-element-invalid-active-border-color) !important;
  --box-shadow: 0 0 0 var(--outline-width) var(--form-element-invalid-focus-color) !important;
}

[dir="rtl"] details[role="list"] summary:is([aria-invalid], [aria-invalid="true"], [aria-invalid="false"]) {
  background-position: .75rem 50%;
}

ol {
  list-style-position: inside;
  padding-inline-start: 0 !important;
}

blockquote {
  margin-block-start: 0;
}

blockquote p:last-child {
  margin-block-end: 0;
}

.icon {
  border-radius: var(--border-radius);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  block-size: 1.5em;
  inline-size: 1.5em;
}

.with_icon {
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  display: inline-flex;
  overflow: hidden;
}

.label_gap {
  margin-inline-end: calc(var(--nav-element-spacing-horizontal) / 2);
}

svg, .icon {
  flex-shrink: 0;
}

button.muted {
  background-color: var(--code-background-color);
  color: var(--code-color);
  border: 0;
}

input[type="checkbox"]:read-only {
  cursor: default;
}

input:not([type="checkbox"], [type="radio"]), select {
  min-inline-size: 4em;
}

.flex select:not([multiple], [size]) {
  background-position: right .25em center;
  padding-inline-end: 0;
}

.modal_wrapper {
  z-index: 3000;
  background-color: #00000080;
  place-items: center;
  block-size: 100vh;
  inline-size: 100vw;
  display: grid;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
}

.modal_wrapper > article.modal {
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  resize: both;
  min-block-size: 90vh;
  max-block-size: 90vh;
  min-inline-size: 90vw;
  max-inline-size: 90vw;
  margin: 1em;
  padding: 1em;
  position: relative;
  overflow: auto;
}

.modal_wrapper > article.modal > div {
  padding: 1em;
}

@media (min-width: 768px) {
  .modal_wrapper > article.modal {
    min-block-size: 25vh;
    min-inline-size: 50vw;
  }

  .modal_wrapper > article.modal:has(.ui_table_wrapper td:nth-child(5)) {
    min-inline-size: 75vw;
  }
}

.modal_wrapper article.alert {
  border-radius: var(--border-radius);
  padding: calc(1.5 * var(--typography-spacing-vertical));
  min-inline-size: 20em;
  margin: auto;
  overflow: auto;
}

.modal_wrapper article.login {
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  padding: 1em;
  position: relative;
  overflow: auto;
}

.button_group {
  gap: var(--spacing);
  flex-wrap: wrap;
  align-items: start;
  margin-block-end: var(--spacing);
  display: flex;
}

.flex > .button_group {
  flex-wrap: nowrap;
}

.flex .zeroWidthSpace {
  display: block !important;
}

.button_group button, .button_group a.link {
  margin-block-end: 0;
}

@media (min-width: 768px) {
  .in_row {
    grid-template-columns: repeat(auto-fit, minmax(0%, 1fr));
    align-items: stretch;
  }

  .dialog button {
    inline-size: 100%;
  }

  .zeroWidthSpace {
    display: block;
  }

  button, a.link {
    inline-size: auto;
    display: inline-flex;
  }

  .button_group {
    flex-wrap: initial;
  }

  .in_row > .button_group:last-child:not(:only-child) > span.button_like:only-child {
    margin-inline-start: auto;
  }
}

.sidebar_wrapper {
  flex-direction: row;
  display: flex;
}

.sidebar {
  --form-element-background-color: var(--background-color);
  background-color: var(--well-background-color);
  border-inline-end: 1px solid var(--table-border-color);
  padding: calc(2 * var(--spacing));
  inline-size: var(--sidebar-width);
  block-size: calc(100vh - var(--menubar-height));
  overscroll-behavior-y: contain;
  z-index: 10;
  transition-property: transform;
  transition-duration: .2s;
  transition-timing-function: ease;
  display: flex;
  position: fixed;
  inset-block-start: var(--menubar-height);
  inset-inline-start: 0;
  transform: translate(-100%);
}

.sidebar_close {
  display: none;
  position: absolute;
  inset-block-start: var(--spacing);
  inset-inline-start: calc(100% + 1px);
}

.sidebar_open {
  z-index: 10;
  padding-inline-start: 4px;
  display: grid;
  position: fixed;
  inset-block-start: calc(var(--spacing)  + var(--menubar-height));
  inset-inline-start: -4px;
}

.sidebar_open, .sidebar_close {
  --font-size: 1.5rem;
  cursor: pointer;
  border-radius: var(--border-radius);
  border: 1px solid var(--well-border-color);
  background-color: var(--well-background-color);
  border-inline-start: 0;
  border-start-start-radius: 0;
  border-end-start-radius: 0;
  place-content: center;
}

.sidebar.open {
  box-shadow: var(--card-box-shadow);
  transform: translate(0);
}

.sidebar.open .sidebar_open {
  display: none;
}

.sidebar.open .sidebar_close {
  display: grid;
}

:is(#root:has(.has_sidebar) .applet, #root:has(.has_menubar) .applet) {
  inline-size: 100%;
  max-inline-size: 100%;
  padding: 0;
}

.sidebar_content {
  padding: calc(2 * var(--spacing));
  flex-direction: column;
  inline-size: 100%;
  display: flex;
}

nav.menubar.topbar {
  padding: 0 var(--spacing) 0 0;
  inline-size: min-content;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
}

#root:has(.user_menubar) nav.topbar {
  display: none;
}

@media (min-width: 768px) {
  .has_menubar {
    --menubar-image-height: 32px;
  }

  .sidebar {
    position: sticky;
    transform: none;
  }

  .sidebar_open, .sidebar_close {
    display: none;
  }

  .has_sidebar .sidebar_content {
    inline-size: calc(100% - var(--sidebar-width)  + calc(2 * var(--spacing)));
  }

  main:has(.sidebar) {
    padding: 0;
  }
}

.sidebar figure.nav_widget li {
  justify-content: start;
  margin-block-end: var(--nav-link-spacing-vertical);
  display: flex;
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

.toast-container {
  inline-size: calc(100% - 2 * var(--spacing));
  gap: var(--spacing);
  pointer-events: none;
  z-index: 5000;
  grid-template-columns: 1fr;
  padding: 0;
  display: grid;
  position: fixed;
  inset-block-end: 1em;
}

@media (min-width: 768px) {
  .toast-container {
    inline-size: 30ch;
    inset-inline-end: 1em;
  }
}

.toast-container .toast {
  box-sizing: border-box;
  will-change: transform;
  margin: 0;
  padding: 1ch;
}

.gallery {
  gap: var(--spacing);
  grid-template-columns: repeat(auto-fit, minmax(calc(50% - var(--spacing) / 2), 1fr));
  grid-auto-rows: fit-content(400px);
  max-block-size: 50vh;
  display: grid;
  overflow: auto;
}

.gallery .gallery-card {
  position: relative;
  overflow: hidden;
}

.gallery-card img.gallery-cover {
  object-fit: cover;
  background-color: #d3d3d3;
  border-radius: .5em;
  block-size: 150px;
  inline-size: 100%;
}

.gallery-card .gallery-card-title {
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: bold;
  display: -webkit-box;
  overflow: hidden;
}

.gallery-card p {
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  font-size: smaller;
  display: -webkit-box;
  overflow: hidden;
}

.gallery-card .gallery-card-selector {
  mix-blend-mode: difference;
  margin: 0;
  position: absolute;
  inset-block-start: .5em;
  inset-inline-end: .5em;
}

@media (min-width: 768px) {
  .gallery-s {
    grid-template-columns: repeat(auto-fit, minmax(calc(15% - var(--spacing)), 1fr));
    grid-auto-rows: fit-content(300px);
  }

  .gallery-s img.gallery-cover {
    block-size: 150px;
  }

  .gallery-m {
    grid-template-columns: repeat(auto-fit, minmax(calc(25% - var(--spacing)), 1fr));
    grid-auto-rows: fit-content(350px);
  }

  .gallery-m img.gallery-cover {
    block-size: 200px;
  }

  .gallery-l {
    grid-template-columns: repeat(auto-fit, minmax(calc(50% - var(--spacing)), 1fr));
    grid-auto-rows: fit-content(400px);
  }

  .gallery-l img.gallery-cover {
    block-size: 250px;
  }
}

.centered {
  z-index: 500;
  place-items: center;
  block-size: 80vh;
  inline-size: 100%;
  display: grid;
}

.centered article {
  padding: 1em 4em 2em;
}

.radio_vertical, .checkbox_vertical, .radio_horizontal, .checkbox_horizontal {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

@media (min-width: 768px) {
  .radio_horizontal, .checkbox_horizontal {
    gap: var(--form-element-spacing-horizontal);
    flex-direction: row;
  }

  .in_row > .grid-span > :is(.radio_group, .checkbox_group) :is(.radio_horizontal, .checkbox_horizontal), .in_row > .boolean_field .checkbox_label, .in_row > .grid-span > .boolean_field .checkbox_label {
    padding: 0;
    padding-block: var(--form-element-spacing-vertical) calc(var(--form-element-spacing-vertical)  + 3 * var(--border-width));
  }
}

label.radio_label, label.checkbox_label {
  white-space: nowrap;
  font-size: var(--font-size);
  --form-label-font-weight: var(--font-weight);
  display: inline-block;
}

nav.menubar {
  padding: 0 calc(2 * var(--spacing));
  z-index: 2000;
  align-items: center;
  inline-size: 100%;
  display: flex;
}

nav.menubar > ul {
  gap: var(--nav-element-spacing-horizontal);
}

nav.menubar > ul > li {
  padding: 0;
}

nav.menubar [role="link"] {
  padding: calc(var(--border-width)  + var(--form-element-spacing-vertical)) calc(var(--border-width)  + var(--form-element-spacing-horizontal));
  margin: 0;
}

nav.menubar [role="link"]:hover {
  --background-color: var(--primary-focus);
}

nav.menubar details[role="list"] summary[role="link"] + ul {
  margin-inline-end: calc(var(--nav-link-spacing-horizontal) * -1);
}

nav.menubar .menu_heading {
  font-weight: bold;
}

nav.menubar .menu_parent:after {
  margin-inline-start: .25rem;
}

nav.menubar h5 {
  margin: 0;
}

nav.menubar.user_menubar {
  block-size: var(--menubar-height);
  background-color: var(--well-background-color);
  border-block-end: var(--border-width) solid var(--table-border-color);
  z-index: 1000;
  position: sticky;
  inset-block-start: 0;
}

nav.menubar.user_menubar > img {
  block-size: var(--menubar-image-height);
  max-inline-size: 33vw;
}

nav.breadcrumb {
  margin-block-end: var(--typography-spacing-vertical);
}

nav.breadcrumb ul {
  gap: var(--nav-element-spacing-horizontal);
}

nav.breadcrumb ul li {
  --font-size: 1.1rem;
  font-size: var(--font-size);
  padding: 0;
  font-weight: 700;
}

nav.breadcrumb .breadcrumb_sep {
  color: var(--muted-color);
  font-weight: lighter;
}

hr {
  margin: var(--typography-spacing-vertical) 0;
  inline-size: 100%;
}

:has( + hr) {
  margin-block-end: 0;
}

:has( + hr) > :last-child {
  margin-block-end: 0;
}

:has( + hr) > :last-child > :last-child {
  margin-block-end: 0;
}

:has( + hr) > :last-child > :last-child > :last-child {
  margin-block-end: 0;
}

:is(.sidebar_content, main) > .region_wrapper:last-child > .ui_table_wrapper:last-child {
  margin-block-end: 0;
}

.tooltip {
  --color: var(--tooltip-color);
  color: var(--tooltip-color);
  background-color: var(--tooltip-background-color);
  border-radius: .25em;
  inline-size: max-content;
  padding: .25em .5em;
  font-size: smaller;
}

.tooltip_inline {
  display: inline-flex;
}

.tooltip svg {
  color: var(--tooltip-background-color);
}

.img_container {
  display: inline-block;
  position: relative;
}

.img_container .btn {
  padding: var(--border-radius);
}

.img_container .btn svg {
  block-size: 16px;
  inline-size: 16px;
  display: block;
}

.lower_right {
  position: absolute;
  inset-block-end: var(--border-radius);
  inset-inline-end: var(--border-radius);
}

@media (hover: hover) {
  .img_container .btn {
    display: none;
  }

  .img_container:hover .btn {
    display: block;
  }
}

.media_busy_wrapper {
  position: relative;
}

.media_busy_wrapper .busy {
  z-index: 1;
  place-content: center;
  block-size: 100%;
  inline-size: 100%;
  display: grid;
  position: absolute;
}

.media_container :is(img, video, iframe) {
  border-radius: var(--border-radius);
  object-fit: cover;
  block-size: 100%;
  inline-size: 100%;
}

audio {
  inline-size: 100%;
}

.media_container.s, img.s, video.s, audio.s {
  max-block-size: max(300px, 25vh);
}

img.s, video.s {
  border-radius: var(--border-radius);
  object-fit: cover;
  display: block;
}

.media_container.m, img.m, video.m, audio.m {
  max-block-size: max(350px, 40vh);
}

img.m, video.m {
  border-radius: var(--border-radius);
  object-fit: cover;
  display: block;
}

.media_container.l, img.l, video.l, audio.l {
  max-block-size: max(400px, 80vh);
  inline-size: 100%;
  max-inline-size: 100%;
}

.in_row img.s, .in_row video.s, .in_row img.m, .in_row video.m, img.l, video.l {
  border-radius: var(--border-radius);
  object-fit: cover;
  block-size: auto;
  display: block;
}

.feather {
  inline-size: var(--font-size);
  block-size: var(--font-size);
  stroke: currentColor;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.addon_container {
  flex-wrap: nowrap;
  align-content: flex-start;
  inline-size: 100%;
  display: flex;
}

.input_postfix, .input_prefix {
  font-size: 1rem;
  font-weight: var(--font-weight);
  line-height: var(--line-height);
  block-size: calc(1rem * var(--line-height)  + var(--form-element-spacing-vertical) * 2 + var(--border-width) * 2);
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  border: var(--border-width) solid var(--form-element-border-color);
  border-radius: var(--border-radius);
  color: var(--code-color);
  background: var(--code-background-color);
  white-space: nowrap;
  margin-block-end: var(--spacing);
  display: inline-block;
}

.input_postfix {
  border-inline-start: 0;
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.input_prefix {
  border-inline-end: 0;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.svg_icon {
  line-height: calc((var(--font-size)  + 1em * var(--line-height)) / 2);
}

.svg_icon svg {
  block-size: 1rem;
  inline-size: 1rem;
  display: inline-block;
}

input.has_postfix {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

input.has_prefix {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.close_icon {
  --font-size: 1.5rem;
  z-index: 10;
  opacity: .75;
  cursor: pointer;
  background-position: center;
  display: block;
  position: absolute;
  inset-block-start: .5em;
  inset-inline-end: .5em;
}

.txt {
  margin-block-end: var(--spacing);
  line-height: 1.5rem;
}

.tooltip.centered_tooltip {
  align-items: center;
  margin-block-start: 2px;
}

.txt_info {
  align-items: center;
  gap: calc(var(--spacing) / 2);
  display: flex;
}

.txt_info svg {
  inline-size: var(--font-size);
  block-size: var(--font-size);
}

.txt_info :is(h1, h2, h3, h4, h5, h6) {
  margin-block: 0;
}

.flex:has(input, select:not([multiple], [size]), .button_like, button) > div.txt {
  padding: calc(var(--form-element-spacing-vertical)  + 2 * var(--border-width)) 0;
  white-space: nowrap;
}

.flex:has(input, select:not([multiple], [size]), .button_like, button) > div > div.txt {
  padding: calc(var(--form-element-spacing-vertical)  + 2 * var(--border-width)) 0;
  white-space: nowrap;
}

@media (min-width: 768px) {
  .in_row:has(input, select:not([multiple], [size]), .button_like, button) > div.txt {
    padding: calc(var(--form-element-spacing-vertical)  + 2 * var(--border-width)) 0;
    white-space: nowrap;
  }

  .in_row:has(input, select:not([multiple], [size]), .button_like, button) > div > div.txt {
    padding: calc(var(--form-element-spacing-vertical)  + 2 * var(--border-width)) 0;
    white-space: nowrap;
  }
}

.flex > div.txt:last-child, .flex > div > div.txt:last-child {
  text-overflow: ellipsis;
  overflow: hidden;
}

.flex > .spring {
  flex-grow: 1;
}

.flex > :first-child:nth-last-child(2):has( + .button_group) {
  flex: 1;
}

.txt-size-xs {
  --font-size: .8rem;
  font-size: .8rem;
}

.txt-size-s {
  --font-size: .95rem;
  font-size: .95rem;
}

.txt-size-l {
  --font-size: 1.3rem;
  font-size: 1.3rem;
}

.txt-size-xl {
  --font-size: 1.6rem;
  font-size: 1.6rem;
}

.txt-align-center {
  text-align: center;
}

.txt-align-end {
  text-align: end;
}

.txt-color-success {
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  color: var(--contrast);
  background-color: var(--color-success);
  border-radius: var(--border-radius);
  border-color: var(--color-success);
}

.txt-color-error {
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  color: var(--contrast);
  background-color: var(--color-error);
  border-radius: var(--border-radius);
  border-color: var(--color-error);
}

.txt-color-neutral {
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  color: var(--contrast);
  background-color: var(--muted-border-color);
  border-radius: var(--border-radius);
  border-color: var(--muted-border-color);
}

.txt-color-muted {
  color: var(--muted-color);
}

details[role="list"] summary:not([role]):has(.pills) {
  padding: var(--form-element-spacing-vertical);
}

details[role="list"] summary:after, li[role="list"] > a:after {
  flex-shrink: 0;
  margin-inline-start: 0;
}

ul[role="menu"] {
  z-index: 99;
  border: var(--border-width) solid var(--dropdown-border-color);
  border-radius: var(--border-radius);
  background-color: var(--dropdown-background-color);
  box-shadow: var(--card-box-shadow);
  color: var(--dropdown-color);
  white-space: nowrap;
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  flex-direction: column;
  max-block-size: max(400px, 50vh);
  margin: 0;
  padding: 0;
  display: flex;
  overflow-y: auto;
}

ul[role="menu"] li {
  padding: calc(var(--form-element-spacing-vertical) * .5) var(--form-element-spacing-horizontal);
  flex-basis: 1px;
  inline-size: 100%;
  margin-block-end: 0;
  list-style: none;
}

ul[role="menu"] li.selected {
  background-color: var(--primary-focus);
}

ul[role="menu"] li:first-of-type {
  margin-block-start: calc(var(--form-element-spacing-vertical) * .5);
}

ul[role="menu"] li:last-of-type {
  margin-block-end: calc(var(--form-element-spacing-vertical) * .5);
}

ul[role="menu"] li a {
  margin: calc(var(--form-element-spacing-vertical) * -.5) calc(var(--form-element-spacing-horizontal) * -1);
  padding: calc(var(--form-element-spacing-vertical) * .5) var(--form-element-spacing-horizontal);
  color: var(--dropdown-color);
  text-overflow: ellipsis;
  text-decoration: none;
  display: block;
  overflow: hidden;
}

ul[role="menu"] li a:hover {
  background-color: var(--dropdown-hover-background-color);
}

ul[role="menu"] li code {
  color: var(--contrast);
}

.indent-1 {
  margin-inline-start: calc(1 * var(--font-size)  + var(--nav-element-spacing-horizontal) / 2);
}

.indent-2 {
  margin-inline-start: calc(2 * var(--font-size)  + var(--nav-element-spacing-horizontal) / 2);
}

.indent-3 {
  margin-inline-start: calc(3 * var(--font-size)  + var(--nav-element-spacing-horizontal) / 2);
}

.indent-4 {
  margin-inline-start: calc(4 * var(--font-size)  + var(--nav-element-spacing-horizontal) / 2);
}

.indent-5 {
  margin-inline-start: calc(5 * var(--font-size)  + var(--nav-element-spacing-horizontal) / 2);
}

.indent-6 {
  margin-inline-start: calc(6 * var(--font-size)  + var(--nav-element-spacing-horizontal) / 2);
}

.indent-7 {
  margin-inline-start: calc(7 * var(--font-size)  + var(--nav-element-spacing-horizontal) / 2);
}

.indent-8 {
  margin-inline-start: calc(8 * var(--font-size)  + var(--nav-element-spacing-horizontal) / 2);
}

.grid-span {
  flex-direction: column;
  display: flex;
}

.grid-align-left {
  justify-self: left;
}

.grid-align-center {
  justify-self: center;
}

.grid-align-right {
  justify-self: end;
}

.flex-align-left {
  justify-content: flex-start;
}

.flex-align-center {
  justify-content: center;
}

.flex-align-right {
  justify-content: flex-end;
}

.flex-align-left > div.txt:last-child, .flex-align-center > div.txt:last-child, .flex-align-right > div.txt:last-child {
  flex-grow: 0;
}

.widget-align-container {
  display: flex;
}

.widget-align-right {
  justify-content: flex-end;
}

.widget-align-left {
  justify-content: flex-start;
}

.widget-align-center {
  justify-content: center;
}

@media (min-width: 768px) {
  .grid-span-2 {
    grid-column: span 2;
  }

  .grid-span-3 {
    grid-column: span 3;
  }

  .grid-span-4 {
    grid-column: span 4;
  }

  .grid-span-5 {
    grid-column: span 5;
  }

  .grid-span-6 {
    grid-column: span 6;
  }

  .grid-span-7 {
    grid-column: span 7;
  }

  .grid-span-8 {
    grid-column: span 8;
  }
}

li[aria-disabled] {
  opacity: var(--form-element-disabled-opacity);
}

nav ol:last-of-type, nav ul:last-of-type {
  margin-inline-end: 0;
}

nav ol:first-of-type, nav ul:first-of-type {
  margin-inline-start: 0;
}

:where(figure.nav_widget nav li):before {
  float: inline-start;
  content: initial;
}

figure.nav_widget nav > ul {
  gap: calc(var(--nav-element-spacing-horizontal) * 1.5);
}

figure.nav_widget li {
  white-space: nowrap;
  text-overflow: ellipsis;
  place-content: center;
  padding: 0;
  display: inline-flex;
  overflow: hidden;
}

figure.nav_widget li .large_icon {
  inline-size: calc(3em + var(--outline-width) * 2);
  block-size: calc(3em + var(--outline-width) * 2);
}

figure.nav_widget li.selected {
  font-weight: bold;
  text-decoration: underline;
}

.sidebar figure.nav_widget {
  overflow-x: initial;
}

.sidebar figure.nav_widget li.selected {
  text-decoration: none;
}

figure.nav_widget li.selected .large_icon {
  margin: var(--outline-width);
  box-shadow: 0 0 0 var(--outline-width) var(--form-element-active-border-color);
}

figure.nav_widget.tabs nav {
  border-block-end: 1px solid var(--form-element-border-color);
}

figure.nav_widget.tabs nav ul {
  margin-inline-start: var(--spacing);
}

figure.nav_widget.tabs li {
  border: 1px solid var(--form-element-border-color);
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  border-radius: var(--border-radius);
  border-block-end: 0;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
  position: relative;
  inset-block-start: 1px;
}

figure.nav_widget.tabs li.selected {
  border-block-end: 1px solid var(--card-background-color);
  text-decoration: none;
}

.info_bar {
  border-radius: var(--border-radius);
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  justify-content: space-between;
  align-items: center;
  margin-block-end: var(--spacing);
  display: flex;
}

.info_bar_msg {
  align-items: center;
  display: flex;
}

.info_bar_msg > svg {
  block-size: 1rem;
  inline-size: 1rem;
}

.info_bar .options {
  align-items: center;
  gap: calc(var(--nav-element-spacing-horizontal) * 2);
  display: flex;
}

#floating-ui-root, div[data-floating-ui-portal] {
  z-index: 6000;
}

.profile_container {
  align-items: center;
  gap: var(--spacing);
  display: flex;
}

.avatar {
  -webkit-user-select: none;
  user-select: none;
  aspect-ratio: 1;
  border: 1px solid var(--table-border-color);
  text-transform: uppercase;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  place-content: center;
  block-size: 1.5em;
  inline-size: 1.5em;
  font-size: 1em;
  display: grid;
  position: relative;
}

.menubar .avatar {
  background-color: var(--primary-focus);
  color: var(--contrast);
}

.stacked_bar {
  inline-size: 100%;
}

.stacked_bar_inner {
  border-radius: var(--border-radius);
  background-color: var(--progress-background-color);
  border: 0;
  block-size: .5rem;
  inline-size: 100%;
  display: inline-flex;
  overflow: hidden;
}

.stacked_bar_inner div {
  block-size: 100%;
}

.ui_chart {
  position: relative;
}

.ui_chart.s canvas {
  max-block-size: max(300px, 25vh);
}

.ui_chart.m canvas {
  max-block-size: max(350px, 33vh);
}

.ui_chart.l canvas {
  max-block-size: max(400px, 50vh);
}

.ui_chart_pie {
  padding: var(--spacing);
}

.ui_chart_pie.s {
  block-size: max(300px, 25vh);
}

.ui_chart_pie.m {
  block-size: max(350px, 33vh);
}

.ui_chart_pie.l {
  padding: calc(2 * var(--spacing));
  block-size: max(400px, 50vh);
}

.google_maps_wrapper {
  border-radius: var(--border-radius);
  inline-size: 100%;
}

.google_maps_wrapper[aria-busy], .google_maps_wrapper[aria-invalid] {
  place-content: center;
  display: grid;
}

.google_maps_wrapper * {
  all: revert;
}

.gm-style-moc {
  pointer-events: none;
}

.google_maps_wrapper.s {
  block-size: max(300px, 25vh);
}

.google_maps_wrapper.m {
  block-size: max(400px, 50vh);
}

.google_maps_wrapper.l {
  block-size: max(600px, 90vh);
}

.joystick {
  border-radius: var(--border-radius);
  background-color: var(--muted-border-color);
  block-size: 200px;
  inline-size: 200px;
  position: relative;
}

.joystick[aria-disabled] {
  opacity: var(--form-element-disabled-opacity);
}

.joystick .bounds {
  border: 2px solid var(--form-element-border-color);
  border-radius: 50%;
  block-size: 150px;
  inline-size: 150px;
  position: absolute;
  inset-block-start: 25px;
  inset-inline-start: 25px;
}

.joystick:focus .bounds {
  border-color: var(--form-element-active-border-color);
}

.joystick .stick {
  background-color: #000;
  border-radius: 50%;
  block-size: 50px;
  inline-size: 50px;
  margin: 75px;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

div.combobox[aria-disabled="true"] {
  pointer-events: none;
}

div.combobox[aria-disabled="true"] summary {
  --form-element-border-color: var(--form-element-disabled-border-color);
  background-color: var(--form-element-disabled-background-color);
  opacity: var(--form-element-disabled-opacity);
}

footer {
  margin-block-start: auto;
}

@media (min-width: 768px) {
  .widgets_grid {
    grid-gap: var(--grid-spacing-vertical) var(--grid-spacing-horizontal);
    grid-template-columns: repeat(auto-fit, minmax(0%, 1fr));
    margin: 0;
    display: grid;
  }
}

.drop_zone {
  color: var(--code-color);
  line-height: calc(var(--spacing)  + 2 * calc(1.5em + var(--form-element-spacing-vertical)  + var(--border-width)));
  border-width: var(--border-width);
  border-style: dashed;
  border-color: var(--form-element-border-color);
  border-radius: var(--border-radius);
  background-color: var(--dropdown-background-color);
  padding: var(--form-element-spacing-vertical) var(--form-element-spacing-horizontal);
  place-content: center;
  margin-block-end: var(--spacing);
  display: grid;
  overflow: hidden;
}

.drop_zone.drag_over {
  background-color: var(--form-element-focus-color);
  cursor: copy;
}

.drop_zone .with_icon {
  display: flex;
}

/*# sourceMappingURL=app.0973ee3c.css.map */
