:root {
  --color-cat-1: #fd7f6f;
  --color-cat-1-mute: rgb(253 127 111 / 50%);
  --color-cat-2: #7eb0d5;
  --color-cat-2-mute: rgb(126 176 213 / 50%);
  --color-cat-3: #b2e061;
  --color-cat-3-mute: rgb(178 224 97 / 50%);
  --color-cat-4: #bd7ebe;
  --color-cat-4-mute: rgb(189 126 190 / 50%);
  --color-cat-5: #ffb55a;
  --color-cat-5-mute: rgb(255 181 90 / 50%);
  --color-cat-6: #ffee65;
  --color-cat-6-mute: rgb(255 238 101 / 50%);
  --color-cat-7: #beb9db;
  --color-cat-7-mute: rgb(190 185 219 / 50%);
  --color-cat-8: #fdcce5;
  --color-cat-8-mute: rgb(253 204 229 / 50%);
  --color-cat-9: #8bd3c7;
  --color-cat-9-mute: rgb(139 211 199 / 50%);
  --color-red: #fd7f6f;
  --color-red-mute: rgb(253 127 111 / 50%);
  --color-blue: #7eb0d5;
  --color-blue-mute: rgb(126 176 213 / 50%);
  --color-green: #b2e061;
  --color-green-mute: rgb(178 224 97 / 50%);
  --color-purple: #bd7ebe;
  --color-purple-mute: rgb(189 126 190 / 50%);
  --color-orange: #ffb55a;
  --color-orange-mute: rgb(255 181 90 / 50%);
  --color-yellow: #ffee65;
  --color-yellow-mute: rgb(255 238 101 / 50%);
  --color-gray: #beb9db;
  --color-gray-mute: rgb(190 185 219 / 50%);
  --color-pink: #fdcce5;
  --color-pink-mute: rgb(253 204 229 / 50%);
  --color-cyan: #8bd3c7;
  --color-cyan-mute: rgb(139 211 199 / 50%);
  --color-success: #b2e061;
  --color-success-mute: rgb(178 224 97 / 50%);
  --color-info: #7eb0d5;
  --color-info-mute: rgb(126 176 213 / 50%);
  --color-error: #fd7f6f;
  --color-error-mute: rgb(253 127 111 / 50%);
}

.color-cat-1 {
  color: var(--color-cat-1);
}

.bgcolor-cat-1 {
  background-color: var(--color-cat-1);
}

.bgcolor-cat-1-mute {
  background-color: var(--color-cat-1-mute);
}

.color-cat-2 {
  color: var(--color-cat-2);
}

.bgcolor-cat-2 {
  background-color: var(--color-cat-2);
}

.bgcolor-cat-2-mute {
  background-color: var(--color-cat-2-mute);
}

.color-cat-3 {
  color: var(--color-cat-3);
}

.bgcolor-cat-3 {
  background-color: var(--color-cat-3);
}

.bgcolor-cat-3-mute {
  background-color: var(--color-cat-3-mute);
}

.color-cat-4 {
  color: var(--color-cat-4);
}

.bgcolor-cat-4 {
  background-color: var(--color-cat-4);
}

.bgcolor-cat-4-mute {
  background-color: var(--color-cat-4-mute);
}

.color-cat-5 {
  color: var(--color-cat-5);
}

.bgcolor-cat-5 {
  background-color: var(--color-cat-5);
}

.bgcolor-cat-5-mute {
  background-color: var(--color-cat-5-mute);
}

.color-cat-6 {
  color: var(--color-cat-6);
}

.bgcolor-cat-6 {
  background-color: var(--color-cat-6);
}

.bgcolor-cat-6-mute {
  background-color: var(--color-cat-6-mute);
}

.color-cat-7 {
  color: var(--color-cat-7);
}

.bgcolor-cat-7 {
  background-color: var(--color-cat-7);
}

.bgcolor-cat-7-mute {
  background-color: var(--color-cat-7-mute);
}

.color-cat-8 {
  color: var(--color-cat-8);
}

.bgcolor-cat-8 {
  background-color: var(--color-cat-8);
}

.bgcolor-cat-8-mute {
  background-color: var(--color-cat-8-mute);
}

.color-cat-9 {
  color: var(--color-cat-9);
}

.bgcolor-cat-9 {
  background-color: var(--color-cat-9);
}

.bgcolor-cat-9-mute {
  background-color: var(--color-cat-9-mute);
}

.color-red {
  color: var(--color-red);
}

.bgcolor-red {
  background-color: var(--color-red);
}

.bgcolor-red-mute {
  background-color: var(--color-red-mute);
}

.color-blue {
  color: var(--color-blue);
}

.bgcolor-blue {
  background-color: var(--color-blue);
}

.bgcolor-blue-mute {
  background-color: var(--color-blue-mute);
}

.color-green {
  color: var(--color-green);
}

.bgcolor-green {
  background-color: var(--color-green);
}

.bgcolor-green-mute {
  background-color: var(--color-green-mute);
}

.color-purple {
  color: var(--color-purple);
}

.bgcolor-purple {
  background-color: var(--color-purple);
}

.bgcolor-purple-mute {
  background-color: var(--color-purple-mute);
}

.color-orange {
  color: var(--color-orange);
}

.bgcolor-orange {
  background-color: var(--color-orange);
}

.bgcolor-orange-mute {
  background-color: var(--color-orange-mute);
}

.color-yellow {
  color: var(--color-yellow);
}

.bgcolor-yellow {
  background-color: var(--color-yellow);
}

.bgcolor-yellow-mute {
  background-color: var(--color-yellow-mute);
}

.color-gray {
  color: var(--color-gray);
}

.bgcolor-gray {
  background-color: var(--color-gray);
}

.bgcolor-gray-mute {
  background-color: var(--color-gray-mute);
}

.color-pink {
  color: var(--color-pink);
}

.bgcolor-pink {
  background-color: var(--color-pink);
}

.bgcolor-pink-mute {
  background-color: var(--color-pink-mute);
}

.color-cyan {
  color: var(--color-cyan);
}

.bgcolor-cyan {
  background-color: var(--color-cyan);
}

.bgcolor-cyan-mute {
  background-color: var(--color-cyan-mute);
}

.color-success {
  color: var(--color-success);
}

.bgcolor-success {
  background-color: var(--color-success);
}

.bgcolor-success-mute {
  background-color: var(--color-success-mute);
}

.color-info {
  color: var(--color-info);
}

.bgcolor-info {
  background-color: var(--color-info);
}

.bgcolor-info-mute {
  background-color: var(--color-info-mute);
}

.color-error {
  color: var(--color-error);
}

.bgcolor-error {
  background-color: var(--color-error);
}

.bgcolor-error-mute {
  background-color: var(--color-error-mute);
}
