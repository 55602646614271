.ui_table_wrapper {
  display: flex;
  flex-direction: column;
  border: var(--border-width) solid var(--table-border-color);
  border-radius: var(--border-radius);
  margin-block-end: var(--typography-spacing-vertical);
}

.ui_table_wrapper figure {
  flex-grow: 1;
  margin-block-end: 0;
  overflow: auto hidden;
  display: flex;
  flex-direction: column;
}

.ui_table_wrapper :is(header, footer) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex: none;
  gap: calc(var(--spacing) / 2);
  padding: calc(var(--spacing) / 2);
  background-color: var(--well-background-color);
}

.ui_table_wrapper header {
  border-block-end: var(--border-width) solid var(--table-border-color);
}

.ui_table_wrapper header label.lbl {
  margin-block-end: 2px;
  flex: none;
  inline-size: 100%;
  max-inline-size: unset;
}

.ui_table_wrapper footer {
  border-block-start: var(--border-width) solid var(--table-border-color);
}

.ui_table_wrapper .ctrl_group {
  display: flex;
  flex-direction: row;
  gap: calc(var(--spacing) / 2);
  justify-content: flex-end;
  align-items: center;
  block-size: 100%;
  inline-size: 100%;
  font-size: 0.8em;
}

.ui_table_wrapper .ctrl_group input[type="text"] {
  --line-height: 1rem;

  margin-block-end: 0;
  font-size: small;
  border-color: var(--table-border-color);
  background-color: var(--card-background-color);
  inline-size: 20ch;
}

.ui_table_wrapper .ctrl_group .busy {
  inline-size: 1em;
  font-size: 0.8em;
}

.ui_table_wrapper .ctrl_group mark {
  color: var(--code-color);
  background-color: var(--code-background-color);
  border-radius: var(--border-radius);
  line-height: 1em;
  padding: var(--form-element-spacing-vertical)
    var(--form-element-spacing-horizontal);
  flex: none;
}

.ui_table_wrapper .ctrl_group button {
  font-size: 1em;
  line-height: 1em;
  padding: var(--form-element-spacing-vertical)
    var(--form-element-spacing-horizontal);
  margin-block-end: 0;
  border: 0;
}

@media (min-width: 768px) {
  .ui_table_wrapper :is(header, footer) {
    flex-direction: row;
    align-items: center;
    gap: 0;
  }

  .ui_table_wrapper header label.lbl {
    flex: 5 1 auto;
    inline-size: auto;
  }

  .ui_table_wrapper .ctrl_group {
    flex: 1 0 auto;
    inline-size: auto;
  }
}

.ui_table_wrapper.s {
  block-size: var(--small-height);
  flex-grow: 1;
}

.ui_table_wrapper.m {
  block-size: var(--medium-height);
  flex-grow: 1;
}

.ui_table_wrapper.l {
  block-size: var(--large-height);
  flex-grow: 1;
}

.ui_table_wrapper.stretch {
  min-block-size: var(--stretch-min-height);
  block-size: 100%;
  max-block-size: 90vh;
  flex-grow: 100;
}

.ui_table_wrapper figure > div {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

table.ui_table {
  min-inline-size: 100%;
  inline-size: auto;
  border-collapse: separate;
  margin-block-end: 0;
}

table.ui_table.fixed {
  table-layout: fixed;
  inline-size: 100%;
}

table.ui_table th {
  background-color: var(--well-background-color);

  --border-width: 1px;

  min-inline-size: 30px;
  white-space: nowrap;
  font-size: small;
  font-weight: 700;
  position: relative;
}

table.ui_table thead.sticky {
  position: sticky;
  inset-block-start: 0;
}

table.ui_table thead th.empty {
  border: 0;
  padding: 0;
}

table.ui_table th,
table.ui_table .col_sort_asc,
table.ui_table .col_sort_desc {
  overflow: hidden;
  text-overflow: ellipsis;
}

table.ui_table .col_sort_asc {
  cursor: s-resize;
}

table.ui_table .col_sort_desc {
  cursor: n-resize;
}

table.ui_table td {
  border-block-end: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-inline-size: 30ch;
  min-inline-size: 3ch;
}

table.ui_table :not(thead, tfoot) > * > td {
  --font-size: 1em;
}

table.ui_table :is(td, th) {
  padding-inline-end: 0;
}

table.ui_table :is(td, th):last-child {
  padding-inline-end: var(--spacing);
}

table.ui_table td progress {
  inline-size: 5em;
  margin: 0;
}

table.ui_table td input[type="checkbox"] {
  margin: 0;
}

table.ui_table td .stacked_bar {
  min-inline-size: 10em;
  display: flex;
}

table.ui_table th.image,
table.ui_table th.avatar,
table.ui_table th.narrow,
table.ui_table td.narrow {
  inline-size: 3%;
}

table.ui_table td svg.feather {
  inline-size: 1rem;
  block-size: 1rem;
  display: block;
}

@media (min-width: 768px) {
  table.ui_table td progress {
    inline-size: 10em;
  }
}

table.ui_table td .img_wrapper {
  block-size: 3em;
  inline-size: 4em;
  background-position: center;
  background-size: cover;
  border-radius: var(--border-radius);
  border: 1px solid var(--table-border-color);
}

table.ui_table td .avatar {
  block-size: 2em;
  inline-size: 2em;
  font-size: 1.5em;
}

table.ui_table td .avatar::before {
  display: grid;
  place-content: center;
  inline-size: 100%;
  block-size: 100%;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  content: attr(data-initials);
  border-radius: 50%;
  aspect-ratio: 1;
  z-index: -1;
}

.col_adj_overlay {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100vw;
  block-size: 100vh;
  cursor: ew-resize;
}

.col_adj_container {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.col_adj {
  cursor: ew-resize;
  inline-size: 1px;
  border-inline-start: 1px solid transparent;
  position: absolute;
  block-size: 1rem;
  margin-block-start: 2px;
  inset-inline-end: 4px;
}

/**
 * Use a pseudo element to make the resize handle draggable when pressing on both sides of the border line.
 */
.col_adj::before {
  content: "\00a0";
  inline-size: 8px;
  opacity: 0;
  transform: translate(-4px, -2px);
  cursor: ew-resize;
  display: block;
  position: absolute;
}

.col_adj_active {
  border-color: var(--primary);
}

table.ui_table thead:hover .col_adj_hover {
  border-color: var(--muted-color);
  opacity: 0.2;
}

/* TODO: handle no-hover case */
table.ui_table th:hover .col_adj_hover {
  border-color: var(--muted-color);
  opacity: 1;
}

table.ui_table th:hover .col_adj_hover:hover {
  border-color: var(--primary);
}

table.ui_table td[data-fmt="multiline"] {
  text-wrap: initial;
}

table.ui_table td[data-fmt="multiline"] > span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  hyphens: auto;
  min-inline-size: 7em;
  max-inline-size: 80ch;
  margin-inline-end: 1em;
}

table.ui_table td[data-fmt="paragraph"] {
  text-wrap: initial;
}

table.ui_table td[data-fmt="paragraph"] > span {
  min-inline-size: 7em;
  max-inline-size: 80ch;
  margin-inline-end: 1em;
}

tr.even {
  background-color: var(--table-row-stripped-background-color);
}

.actions {
  display: flex;
  gap: var(--spacing);
}

.actions span[role="link"] {
  white-space: nowrap;
}

@media (hover: hover) {
  .hover_action span[role="link"] {
    visibility: hidden;
  }

  .is_adjusting_col_widths .hover_action span[role="link"] {
    visibility: visible;
  }

  tr:hover .hover_action span[role="link"] {
    visibility: visible;
  }
}

table.ui_table td:last-child .actions_end {
  justify-content: end;
  text-align: end;
}

.action_menu_button {
  --padding: calc(var(--spacing) / 2);
  --negative-padding: calc(-1 * var(--padding));

  display: inline-block;
  padding: var(--padding);
  margin: var(--negative-padding);
  border-radius: var(--border-radius);
}

.action_menu_button[data-open] {
  background-color: var(--primary-focus);
}

table.ui_table td svg {
  inline-size: 1rem;
  block-size: 1rem;
  display: inline-block;
  vertical-align: text-top;
}

table.ui_table td .with_icon svg {
  margin-block-start: 1px;
}
