.popup_menu {
  display: flex;
  position: absolute;
  flex-direction: column;
  user-select: none;
  border-radius: var(--border-radius) !important;
}

.popup_menu:focus-visible {
  outline: none;
}

.popup_menu *:focus-visible {
  outline: none;
  background-color: var(--primary-focus);
}

.popup_menu li[role="separator"] {
  margin: 0;
  pointer-events: none;
}

.popup_menu li[role="separator"] hr {
  margin: 0;
}

.popup_menu li {
  display: flex;
  align-items: center;
}

.popup_menu li.menu_heading {
  pointer-events: none;
  font-weight: bold;
}

[data-custom-context-menu="true"] {
  /* user-select: none; */
}

.menu_parent::after {
  flex-shrink: 0;
  inline-size: 1rem;
  block-size: calc(1rem * var(--line-height, 1.5));
  content: "";
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 1rem;
  display: block;
  background-image: var(--icon-chevron);
  transition: transform var(--transition);
}

.menu_parent[data-open="true"] {
  background-color: var(--primary-focus);
}
