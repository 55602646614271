/* @generated by ./scripts/gencalendarcss.js */
@charset "UTF-8";

.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  appearance: button;
  cursor: pointer;
}

button[disabled].rbc-btn {
  cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.rbc-calendar {
  box-sizing: border-box;
  block-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *::before,
.rbc-calendar *::after {
  box-sizing: inherit;
}

.rbc-abs-full,
.rbc-row-bg {
  overflow: hidden;
  position: absolute;
  inset: 0;
}

.rbc-ellipsis,
.rbc-show-more,
.rbc-row-segment .rbc-event-content,
.rbc-event-label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: var(--muted-color);
}

.rbc-off-range-bg {
  background: var(--well-border-color);
}

.rbc-header {
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-block-size: 0;
  border-block-end: 1px solid var(--table-border-color);
}

.rbc-header + .rbc-header {
  border-inline-start: 1px solid var(--table-border-color);
}

.rbc-rtl .rbc-header + .rbc-header {
  border-inline-start-width: 0;
  border-inline-end: 1px solid var(--table-border-color);
}

.rbc-header > a,
.rbc-header > a:active,
.rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-button-link {
  color: inherit;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  user-select: text;
}

.rbc-row-content {
  position: relative;
  user-select: none;
  z-index: 4;
}

.rbc-row-content-scrollable {
  display: flex;
  flex-direction: column;
  block-size: 100%;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container {
  block-size: 100%;
  overflow-y: scroll;

  /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.rbc-row-content-scrollable
  .rbc-row-content-scroll-container::-webkit-scrollbar {
  display: none;
}

.rbc-today {
  background-color: var(--primary-focus);
}

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-block-end: 10px;
  font-size: 16px;
}

.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
}

.rbc-toolbar button {
  color: var(--code-color);
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid var(--dropdown-border-color);
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 12.5%);
  background-color: var(--well-background-color);
  border-color: var(--dropdown-border-color);
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
  color: var(--code-color);
  background-color: var(--primary-focus);
  border-color: var(--dropdown-border-color);
}

.rbc-toolbar button:focus {
  color: var(--code-color);
  background-color: var(--well-background-color);
  border-color: var(--dropdown-border-color);
}

.rbc-toolbar button:hover {
  color: var(--code-color);
  background-color: var(--well-background-color);
  border-color: var(--dropdown-border-color);
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}

.rbc-btn-group > button:first-child:not(:last-child) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.rbc-btn-group > button:last-child:not(:first-child) {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 4px;
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.rbc-btn-group > button:not(:first-child, :last-child) {
  border-radius: 0;
}

.rbc-btn-group button + button {
  margin-inline-start: -1px;
}

.rbc-rtl .rbc-btn-group button + button {
  margin-inline: 0 -1px;
}

.rbc-btn-group + .rbc-btn-group,
.rbc-btn-group + button {
  margin-inline-start: 10px;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: var(--form-element-spacing-vertical)
    var(--form-element-spacing-horizontal);
  background-color: var(--primary);
  border-radius: var(--border-radius);
  color: var(--color-cat-2-mute);
  cursor: pointer;
  inline-size: 100%;
  text-align: start;
}

.rbc-slot-selecting .rbc-event,
.rbc-slot-selecting .rbc-day-slot .rbc-background-event,
.rbc-day-slot .rbc-slot-selecting .rbc-background-event {
  cursor: inherit;
  pointer-events: none;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: var(--well-background-color);
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: 5px auto var(--primary-focus);
}

.rbc-event-label {
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0 rgb(51 51 51 / 50%);
}

.rbc-event-continues-prior {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.rbc-event-continues-after {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.rbc-event-continues-earlier {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.rbc-event-continues-later {
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

.rbc-row {
  display: flex;
  flex-direction: row;
}

.rbc-row-segment {
  padding: 0 1px 1px;
}

.rbc-selected-cell {
  background-color: var(--primary-focus);
}

.rbc-show-more {
  background-color: rgb(255 255 255 / 30%);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  block-size: auto;
  line-height: normal;
  color: var(--primary);
}

.rbc-show-more:hover,
.rbc-show-more:focus {
  color: var(--well-background-color);
}

.rbc-month-view {
  position: relative;
  border: 1px solid var(--table-border-color);
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  inline-size: 100%;
  user-select: none;
  block-size: 100%;
}

.rbc-month-header {
  display: flex;
  flex-direction: row;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0;
  overflow: hidden;
  block-size: 100%;
}

.rbc-month-row + .rbc-month-row {
  border-block-start: 1px solid var(--table-border-color);
}

.rbc-date-cell {
  flex: 1 1 0;
  min-inline-size: 0;
  padding-inline-end: 5px;
  text-align: end;
}

.rbc-date-cell.rbc-now {
  font-weight: bold;
}

.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden;
}

.rbc-day-bg {
  flex: 1 0 0%;
}

.rbc-day-bg + .rbc-day-bg {
  border-inline-start: 1px solid var(--table-border-color);
}

.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-inline-start-width: 0;
  border-inline-end: 1px solid var(--table-border-color);
}

.rbc-overlay {
  position: absolute;
  z-index: 5;
  border: 1px solid var(--table-border-color);
  background-color: var(--background-color);
  box-shadow: 0 5px 15px rgb(0 0 0 / 25%);
  padding: 10px;
}

.rbc-overlay > * + * {
  margin-block-start: 1px;
}

.rbc-overlay-header {
  border-block-end: 1px solid var(--table-border-color);
  margin: -10px -10px 5px;
  padding: 2px 10px;
}

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
}

.rbc-agenda-view table.rbc-agenda-table {
  inline-size: 100%;
  border: 1px solid var(--table-border-color);
  border-spacing: 0;
  border-collapse: collapse;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 5px 10px;
  vertical-align: top;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-inline: 15px;
  text-transform: lowercase;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-inline-start: 1px solid var(--table-border-color);
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-inline-start-width: 0;
  border-inline-end: 1px solid var(--table-border-color);
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-block-start: 1px solid var(--table-border-color);
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  padding: 3px 5px;
  text-align: start;
  border-block-end: 1px solid var(--table-border-color);
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: end;
}

.rbc-agenda-time-cell {
  text-transform: lowercase;
}

.rbc-agenda-time-cell .rbc-continues-after::after {
  content: " »";
}

.rbc-agenda-time-cell .rbc-continues-prior::before {
  content: "« ";
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}

.rbc-agenda-event-cell {
  inline-size: 100%;
}

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-block-size: 100%;
}

.rbc-time-column .rbc-timeslot-group {
  flex: 1;
}

.rbc-timeslot-group {
  border-block-end: 1px solid var(--table-border-color);
  min-block-size: 40px;
  display: flex;
  flex-flow: column nowrap;
}

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}

.rbc-label {
  padding: 0 5px;
}

.rbc-day-slot {
  position: relative;
}

.rbc-day-slot .rbc-events-container {
  position: absolute;
  margin-inline-end: 10px;
  inset: 0;
}

.rbc-day-slot .rbc-events-container.rbc-rtl {
  inset-inline: 10px 0;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 1px solid none;
  display: flex;
  max-block-size: 100%;
  min-block-size: 20px;
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}

.rbc-day-slot .rbc-background-event {
  opacity: 0.75;
}

.rbc-day-slot .rbc-event-label {
  flex: none;
  padding-inline-end: 5px;
  inline-size: auto;
}

.rbc-day-slot .rbc-event-content {
  inline-size: 100%;
  flex: 1 1 0;
  word-wrap: break-word;
  line-height: 1;
  block-size: 100%;
  min-block-size: 1em;
}

.rbc-day-slot .rbc-time-slot {
  border-block-start: 1px solid var(--time-slot-border-separator-color);
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: sticky;
  inset-inline-start: 0;
  background-color: white;
  border-inline-end: 1px solid var(--table-border-color);
  z-index: 10;
  margin-inline-end: -1px;
}

.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}

.rbc-time-view-resources .rbc-time-header-content {
  min-inline-size: auto;
  flex: 1 0 0;
  flex-basis: 0;
}

.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-view-resources .rbc-day-slot {
  min-inline-size: 140px;
}

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  inline-size: 140px;
  flex: 1 1 0;
  flex-basis: 0 px;
}

.rbc-time-header-content + .rbc-time-header-content {
  margin-inline-start: -1px;
}

.rbc-time-slot {
  flex: 1 0 0;
}

.rbc-time-slot.rbc-now {
  font-weight: bold;
}

.rbc-day-header {
  text-align: center;
}

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: var(--primary-focus);
  color: var(--primary);
  font-size: 75%;
  inline-size: 100%;
  padding: 3px;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  inline-size: 100%;
  border: 1px solid var(--table-border-color);
  min-block-size: 0;
}

.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
  text-align: end;
}

.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  inline-size: 100%;
  block-size: 100%;
  position: relative;
}

.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-inline-start: 1px solid var(--table-border-color);
}

.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}

.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-block-size: 20px;
}

.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}

.rbc-time-header.rbc-overflowing {
  border-inline-end: 1px solid var(--table-border-color);
}

.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-inline-end-width: 0;
  border-inline-start: 1px solid var(--table-border-color);
}

.rbc-time-header > .rbc-row:first-child {
  border-block-end: 1px solid var(--table-border-color);
}

.rbc-time-header > .rbc-row.rbc-row-resource {
  border-block-end: 1px solid var(--table-border-color);
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-inline-size: 0;
  flex-direction: column;
  border-inline-start: 1px solid var(--table-border-color);
}

.rbc-rtl .rbc-time-header-content {
  border-inline-start-width: 0;
  border-inline-end: 1px solid var(--table-border-color);
}

.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-block-end: 1px solid var(--table-border-color);
  flex-shrink: 0;
}

.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  inline-size: 100%;
  border-block-start: 2px solid var(--table-border-color);
  overflow-y: auto;
  position: relative;
}

.rbc-time-content > .rbc-time-gutter {
  flex: none;
}

.rbc-time-content > * + * > * {
  border-inline-start: 1px solid var(--table-border-color);
}

.rbc-rtl .rbc-time-content > * + * > * {
  border-inline-start-width: 0;
  border-inline-end: 1px solid var(--table-border-color);
}

.rbc-time-content > .rbc-day-slot {
  inline-size: 100%;
  user-select: none;
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  inset-inline: 0;
  block-size: 1px;
  background-color: var(--primary);
  pointer-events: none;
}

.rbc-addons-dnd .rbc-addons-dnd-row-body {
  position: relative;
}

.rbc-addons-dnd .rbc-addons-dnd-drag-row {
  position: absolute;
  inset-block-start: 0;
  inset-inline: 0;
}

.rbc-addons-dnd .rbc-addons-dnd-over {
  background-color: var(--calendar-dnd-hover);
}

.rbc-addons-dnd .rbc-event,
.rbc-addons-dnd .rbc-day-slot .rbc-background-event,
.rbc-day-slot .rbc-addons-dnd .rbc-background-event {
  transition: opacity 150ms;
}

.rbc-addons-dnd .rbc-event:hover .rbc-addons-dnd-resize-ns-icon,
.rbc-addons-dnd
  .rbc-day-slot
  .rbc-background-event:hover
  .rbc-addons-dnd-resize-ns-icon,
.rbc-day-slot
  .rbc-addons-dnd
  .rbc-background-event:hover
  .rbc-addons-dnd-resize-ns-icon,
.rbc-addons-dnd .rbc-event:hover .rbc-addons-dnd-resize-ew-icon,
.rbc-addons-dnd
  .rbc-day-slot
  .rbc-background-event:hover
  .rbc-addons-dnd-resize-ew-icon,
.rbc-day-slot
  .rbc-addons-dnd
  .rbc-background-event:hover
  .rbc-addons-dnd-resize-ew-icon {
  display: block;
}

.rbc-addons-dnd .rbc-addons-dnd-dragged-event {
  opacity: 0;
}

.rbc-addons-dnd.rbc-addons-dnd-is-dragging
  .rbc-event:not(.rbc-addons-dnd-dragged-event, .rbc-addons-dnd-drag-preview),
.rbc-addons-dnd.rbc-addons-dnd-is-dragging
  .rbc-day-slot
  .rbc-background-event:not(
    .rbc-addons-dnd-dragged-event,
    .rbc-addons-dnd-drag-preview
  ),
.rbc-day-slot
  .rbc-addons-dnd.rbc-addons-dnd-is-dragging
  .rbc-background-event:not(
    .rbc-addons-dnd-dragged-event,
    .rbc-addons-dnd-drag-preview
  ) {
  opacity: 0.5;
}

.rbc-addons-dnd .rbc-addons-dnd-resizable {
  position: relative;
  inline-size: 100%;
  block-size: 100%;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor {
  inline-size: 100%;
  text-align: center;
  position: absolute;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:first-child {
  inset-block-start: 0;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:last-child {
  inset-block-end: 0;
}

.rbc-addons-dnd
  .rbc-addons-dnd-resize-ns-anchor
  .rbc-addons-dnd-resize-ns-icon {
  display: none;
  border-block-start: 3px double;
  margin: 0 auto;
  inline-size: 10px;
  cursor: ns-resize;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor {
  position: absolute;
  inset-block: 4px 0;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor:first-child {
  inset-inline-start: 0;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor:last-child {
  inset-inline-end: 0;
}

.rbc-addons-dnd
  .rbc-addons-dnd-resize-ew-anchor
  .rbc-addons-dnd-resize-ew-icon {
  display: none;
  border-inline-start: 3px double;
  margin-block: auto;
  block-size: 10px;
  cursor: ew-resize;
}
