.ui_calendar_wrapper.s {
  block-size: var(--small-height);
}

.ui_calendar_wrapper.m {
  block-size: var(--medium-height);
}

.ui_calendar_wrapper.l {
  block-size: var(--large-height);
}

.ui_calendar_wrapper.stretch {
  min-block-size: var(--stretch-min-height);
  block-size: 90vh;
  max-block-size: 90vh;
  flex-grow: 100;
}

.rbc-calendar {
  background-color: var(--well-background-color);
  padding: calc(var(--spacing) / 2);
  border-radius: var(--border-radius);
  border: var(--border-width) solid var(--table-border-color);
}

.rbc-toolbar {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  font-weight: var(--form-label-font-weight);
  margin-block-end: calc(var(--spacing) / 2);
  justify-content: space-between;
}

.rbc-toolbar .pagination {
  gap: var(--spacing);
  margin-inline-start: auto;
}

.rbc-toolbar .pagination .busy {
  inline-size: 1em;
}

.rbc-toolbar .lbl.title {
  text-align: center;
}

.rbc-btn-group {
  display: flex;
  align-items: center;
  color: var(--muted-color);
}

.rbc-toolbar button {
  border-width: var(--border-width);
  color: var(--code-color);
  background-color: var(--code-background-color);
  border-radius: var(--border-radius);
  padding: var(--form-element-spacing-vertical)
    var(--form-element-spacing-horizontal);
  font-size: 0.85rem;
  min-inline-size: unset;
}

.rbc-toolbar button:focus,
.rbc-toolbar button:hover {
  color: var(--form-element-color);
  background-color: var(--well-background-color);

  /* border-color: var(--form-element-border-color); */
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  background-color: var(--primary-focus);
  border-color: none;
  box-shadow: none;
  color: var(--form-element-color);
}

.rbc-button-link {
  font-size: 0.85rem;
}

.rbc-time-content {
  border-block-start: 1px solid var(--table-border-color);
  font-size: 0.85rem;
}

.rbc-time-content > .rbc-time-gutter {
  flex: none;
}

.rbc-current-time-indicator {
  background-color: var(--primary);
  block-size: var(--outline-width);
}

.rbc-day-slot .rbc-event {
  flex-flow: column-reverse nowrap;
  border: none;
  justify-content: flex-end;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: var(--color-cat-2-mute);
  color: var(--contrast);
  font-size: 0.85rem;
}

.rbc-event:hover {
  background-color: var(--color-cat-2);
}

.rbc-day-slot .rbc-time-slot {
  border-block-start: none;
}

.rbc-off-range-bg {
  background-color: var(--well-border-color);
}

.rbc-show-more {
  color: unset;
  font-weight: normal;
  font-size: 0.85rem;
}

.rbc-show-more:hover,
.rbc-show-more:focus {
  color: var(--primary-hover);
}

.rbc-calendar-title {
  margin-inline-end: var(--spacing);
}
